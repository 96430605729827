import React, { FC } from 'react';

interface ButtonProps {
  classes?: string;
  bg?: 'Red' | 'Orange' | 'Green' | 'Blue' | 'Purple';
  label?: string;
  onAction: () => void;
}

const Button: FC<ButtonProps> = props => {
  const {
    label = '',
    bg = 'Orange',
    classes = '',
    onAction = () => {}
  } = props;

  const bgColor = () => {
    switch (bg) {
      case 'Red':
        return 'bg-[#d21d1e]';
      case 'Orange':
        return 'bg-[#d2a51f]';
      case 'Green':
        return 'bg-[#46c41c]';
      case 'Blue':
        return 'bg-[#1e87d2]';
      case 'Purple':
        return 'bg-[#691fd2]';
      default:
        break;
    }
  }

  return (
    <span
      className={`
        rounded text-white cursor-pointer px-3 py-1.5 text-center
        ${bgColor()}
        ${classes}
      `}
      onClick={() => onAction()}
    >
      {label}
    </span>
  )
}

export default Button;
