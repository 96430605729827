import { APIS } from "../helper/constants";
import AxiosInstance from "./apiClient"

const fetchNewServerSeed = async () => {
  const res = await AxiosInstance.post(APIS.NEW_SERVERSEED);
  return res.data;
}

const putResetNonce = async () => {
  const res = await AxiosInstance.post(APIS.INIT_NONCE);
  return res.data;
}

export default {
  fetchNewServerSeed,
  putResetNonce,
}
