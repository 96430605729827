import * as Types from "./types"

import diceIcon from '../assets/icons/dice-icon.svg';
import rouletteIcon from '../assets/icons/roulette-icon.svg';
import blackjackIcon from '../assets/icons/blackjack-icon.svg';
import pokerIcon from '../assets/icons/poker-icon.svg';
import bitsbarsIcon from '../assets/icons/bits&bars-icon.svg';
import bitdropIcon from '../assets/icons/bitdrop-icon.svg';
import moreIcon from '../assets/icons/more (5).svg';

export const CIRCLE_RATE = 3.141592;

export const APP_ROUTES = {
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  DICE: '/dice',
  ROULETEE: '/roulette',
  BLACKJACK: '/blackjack',
  POCKER: '/pocker',
  BITSANDBARS: '/bits-bars',
  BITDROP: '/bitdrop',
  MORE: '/more',
  CASINO: '/casino',
  WINNERS: '/winners',
  CONTACT: '/contact',
  SETTING: '/setting',
}

export const LEFT_SIDEBAR: Types.SidebarItem[] = [
  { link: APP_ROUTES.BITDROP, icon: bitdropIcon, label: 'Bit Drop' },
  { link: APP_ROUTES.DICE, icon: diceIcon, label: 'Dice' },
  { link: APP_ROUTES.ROULETEE, icon: rouletteIcon, label: 'Roulette' },
  { link: APP_ROUTES.BLACKJACK, icon: blackjackIcon, label: 'Blackjack' },
  { link: APP_ROUTES.POCKER, icon: pokerIcon, label: 'Poker' },
  { link: APP_ROUTES.BITSANDBARS, icon: bitsbarsIcon, label: 'Bits & Bars' },
  { link: APP_ROUTES.MORE, icon: moreIcon, label: 'More Games' },
]

export const APIS = {
  NEW_SERVERSEED: '/user/new-seed',
  INIT_NONCE: '/user/init-nonce',
  PLINKO: '/plinko',
  PLINKO_VERIFY: '/plinko/verify',
  DICE: '/dice',
  DICE_VERIFY: '/dice/verify',
  ROULETTE: '/roulette',
  ROULETTE_VERIFY: '/roulette/verify',
}

export const VerifyDesc = {
  common: [
    "You may use this page to validate any game. The result of every game should be equal to: ",
    "SHA256(ServerSeed,UserSeed|Nonce)",
  ],
  dice: "Take 6 hex digits, convert it to decimal, mod it by 10,000, and divide it by 100. That's the roll value.",
  bitdrop: "Take 4 hex digits, convert to binary, move left for 0, and right for 1. Repeat with the next 4 hex digits for each subsequent bit dropped until you've determined where each bit has fallen, multiply the amount of bits that fell into a prize by its multiplier, and finally by your bet. Do this for every prize, and it will result in your payout.",
  roulette: "Take 8 hex digits, convert it to an int32 value, mod it by 37. That's the roll value.",
  bitdropProof: [
    "The result of every game should be equal to:",
    "SHA256(ServerSeed,PlayerSeed|Nonce)",
    "You may request a new server seed any time to allow for verification of your previous bets. The ServerHash should equal ",
    "SHA256(ServerSeed)",
  ]
}

/**
 * Plinkgo game
 */
export const PlinkoBallLimit = 10;
export const PREV_GAME='previous_game';

export const ColorList = [
  'Red', 'Orange', 'Green', 'Blue', 'Purple'
]

// eslint-disable-next-line
export const ColorValueList: any = {
  'Red': {
    color: '#d21d1e',
    values: [777, 77.7, 27.1, 7.77, 2.77, 2.22, 0.77, 0.11, 0,  0.11, 0.77, 2.22, 2.77, 7.77, 27.1, 77.7, 777],
    result: '5 mBTC'
  },
  'Orange': {
    color: '#d2a51f',
    values: [200, 50, 25, 7.8, 3, 1.2, 1.1, 0.5, 0.4, 0.5, 1.1, 1.2, 3, 7.8, 25, 50, 200],
    result: '10 mBTC'
  },
  'Green': {
    color: '#46c41c',
    values: [50, 20, 7, 5, 3, 1.2, 1.1, 0.5, 0.4, 0.5, 1.1, 1.2, 3, 5, 7, 20, 50],
    result: '25 mBTC'
  },
  'Blue': {
    color: '#1e87d2',
    values: [12, 6, 2.6, 2, 1.3, 1.25, 1.05, 1, 0.4, 1, 1.05, 1.25, 1.3, 2, 2.6, 6, 12],
    result: '75 mBTC'
  },
  'Purple': {
    color: '#691fd2',
    values: [4, 3, 2, 1.3, 1.15, 0, 1.13, 1.12, 1.11, 1.12, 1.13, 0, 1.15, 1.3, 2, 3, 4],
    result: '100 mBTC'
  },
}

// eslint-disable-next-line
export const ColorCalValueList: any = {
  Red: [0, 0.11, 0.77, 2.22, 2.77, 7.77, 27.1, 77.7, 777],
  Orange: [0.4, 0.5, 1.1, 1.2, 3, 7.8, 25, 50, 200],
  Green: [0.4, 0.5, 1.1, 1.2, 3, 5, 7, 20, 50],
  Blue: [0.4, 1, 1.05, 1.25, 1.3, 2, 2.6, 6, 12],
  Purple: [1.11, 1.12, 1.13, 0, 1.15, 1.3, 2, 3, 4],
};

export const BallStep = 16;

/**
 * Roulette game
 */
export const NumbersArea = {
  'first-line': [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
  'second-line': [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
  'third-line': [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
  'first-12': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  'second-12': [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  'third-12': [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
  'first-18': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  'second-18': [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
  'even': [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
  'odd': [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35],
  'red': [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
  'dark': [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
}

export const ChipList: Types.ChipItemType[] = [
  { value: 1, label: '1', bg: 'bg-gray-400' },
  { value: 5, label: '5', bg: 'bg-yellow-600' },
  { value: 10, label: '10', bg: 'bg-red-700' },
  { value: 50, label: '50', bg: 'bg-blue-700' },
  { value: 100, label: '100', bg: 'bg-green-800' },
  { value: 500, label: '500', bg: 'bg-green-600' },
  { value: 1000, label: '1K', bg: 'bg-orange-500' },
  { value: 5000, label: '5K', bg: 'bg-gray-800' },
  { value: 10000, label: '10K', bg: 'bg-pink-400' },
  { value: 50000, label: '50K', bg: 'bg-purple-700' },
  { value: 100000, label: '100K', bg: 'bg-gray-900' },
  { value: 500000, label: '500K', bg: 'bg-blue-500' },
  { value: 1000000, label: '.01B', bg: 'bg-orange-800' },
  { value: 5000000, label: '.05B', bg: 'bg-cyan-700' },
  { value: 10000000, label: '.10B', bg: 'bg-green-900' },
]
