import React, { FC } from 'react';
import { BallItem } from '../../../helper/types';

interface BallProps {
  data: BallItem,
  bg: string;
}

const Ball: FC<BallProps> = props => {
  const { data, bg } = props;

  const customStyle = (position: BallItem) => (
    {
      transform: `translate(${position.offsetX}px, ${position.offsetY}px)`,
      left: 'calc(50% - 8px)',
      opacity: position.disable ? 0 : 1,
      background: bgColor()
    }
  )

  const bgColor = () => {
    switch (bg) {
      case 'Red':
        return '#d21d1e';
      case 'Orange':
        return '#d2a51f';
      case 'Green':
        return '#46c41c';
      case 'Blue':
        return '#1e87d2';
      case 'Purple':
        return '#691fd2';
      default:
        break;
    }
  }

  return (
    <div
      className={`bg-green1 w-2 h-2 md:w-4 md:h-4 rounded-full absolute -top-2 ml-1 md:ml-0 md:-top-3.5 left-1/2 duration-300 ease-in`}
      style={customStyle(data)}
    ></div>
  )
}

export default Ball;
