import React, { FC, useState } from "react";
import { AiOutlineSetting } from 'react-icons/ai';
import { BsKeyboard } from 'react-icons/bs';
import { GoLaw } from 'react-icons/go';

import CommonLayout from "../../layouts/CommonLayout";
import BetDataTable from "../../components/BetDataTable";
import IconButton from "../../components/IconButton";
import Text from "../../components/Text";
import bitcoinIcon from '../../assets/icons/bitcoin.svg';

const DashboardPage: FC = () => {
  const tabList = ['MANNUAL', 'AUTOMATIC', 'LIGHTING', 'FANCY ROLL'];
  
  const [tab, setTab] = useState<string>('MANNUAL')

  const handleSubCategory = (category: string) => {
    console.log('transaction ', category)
  }

  return (
    <CommonLayout classes="px-4 xl:px-16">
      <div className="flex flex-col mb-4 gap-2 px-0 xl:px-16">
        <div className="flex flex-row items-center justify-center gap-2">
          <IconButton classes="!bg-gray1 !py-2.5" onAction={() => handleSubCategory('setting')}>
            <div className="flex flex-row gap-2">
              <AiOutlineSetting />
              <Text type="xs-white" label="Settings" />
            </div>
          </IconButton>
          <IconButton classes="!bg-gray1 !py-2.5" onAction={() => handleSubCategory('hotkey')}>
            <div className="flex flex-row gap-2">
              <BsKeyboard />
              <Text type="xs-white" label="Hotkeys" />
            </div>
          </IconButton>
          <IconButton classes="!bg-gray1 !py-2.5" onAction={() => handleSubCategory('hotkey')}>
            <div className="flex flex-row gap-2">
              <GoLaw />
              <Text type="xs-white" label="Fairness" />
            </div>
          </IconButton>
        </div>
        <div className="flex flex-col xl:flex-row justify-between bg-transparent xl:bg-gray1 px-4 py-2.5 rounded gap-2">
          <div className="flex flex-row items-center gap-4 bg-gray1 xl:bg-transparent px-4 py-2">
            <Text type="sm-dark" label="Wins" />
            <Text type="sm-white" label="0" />
          </div>
          <div className="flex flex-row items-center gap-4 bg-gray1 xl:bg-transparent px-4 py-2">
            <Text type="sm-dark" label="Losses" />
            <Text type="sm-white" label="0" />
          </div>
          <div className="flex flex-row items-center gap-4 bg-gray1 xl:bg-transparent px-4 py-2">
            <Text type="sm-dark" label="Wagered" />
            <div className="flex flex-row items-center gap-1">
              <img src={bitcoinIcon} />
              <Text type="sm-white" label="0.00008785" />
            </div>
          </div>
          <div className="flex flex-row items-center gap-4 bg-gray1 xl:bg-transparent px-4 py-2">
            <Text type="sm-dark" label="Profit" />
            <div className="flex flex-row items-center gap-1">
              <img src={bitcoinIcon} />
              <Text type="sm-white" label="0.00008785" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className='flex flex-row overflow-auto px-2 xl:px-12 gap-4 xl:gap-12 border-b border-gray2'>
            {tabList.map((item, index) => (
              <div
                key={index}
                className={`pt-2 pb-1.5 cursor-pointer ${tab === item && 'border-b border-white'}`}
                onClick={() => setTab(item)}
              >
                <Text label={item} type={tab === item ? 'xs-white' : 'xs-dark'} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <BetDataTable type="dashboard" />
    </CommonLayout>
  )
}

export default DashboardPage
