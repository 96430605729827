import React, { createContext, useState } from 'react'
import { BetListItem, BetUserItem } from '../helper/types';

export interface IBalance {
    btc: number;
    eth: number;
    bch: number;
    ltc: number;
    doge: number;
    ht: number;
}

export interface IGameResult {
    serverSeed: string;
    playerSeed: string;
    result: string;
    newServerSeedHash: string;
}

export interface INewSeed {
    serverSeed: string;
    playerSeed: string;
}

interface IAppContext {
    balance: IBalance;
    curBalance: number;
    betList: BetListItem[];
    userBetList: BetListItem[];
    bigRollers: BetListItem[];
    visibleMobileMenu: boolean;
    winners: BetUserItem[];
    gameResult: IGameResult;
    newSeed: INewSeed;
    
    updateCurBalance: (value: number) => void,
    updateBalance: (data: Partial<IBalance>) => void,
    updateBetList: (data: BetListItem, reset?: boolean) => void,
    updateUserBetList: (data: BetListItem, reset?: boolean) => void,
    updateBigRollers: (data: BetListItem) => void,
    updateWinners: (data: BetUserItem[]) => void,
    initialize: () => void,
    updateVisibleMobileMenu: (status: boolean) => void,
    updateGameResult: (data: IGameResult) => void,
    updateNewSeed: (data: INewSeed) => void,
}


const initialState = {
    balance: {} as IBalance,
    curBalance: 0,
    betList: [],
    userBetList: [],
    bigRollers: [],
    winners: [],
    visibleMobileMenu: false,
    gameResult: {} as IGameResult,
    newSeed: {} as INewSeed,

    updateCurBalance: () => {},
    updateBalance: () => {},
    updateBetList: () => {},
    updateUserBetList: () => {},
    updateBigRollers: () => {},
    updateWinners: () => {},
    updateVisibleMobileMenu: () => {},
    updateGameResult: () => {},
    updateNewSeed: () => {},
}

const AppContext = createContext({} as IAppContext)

// eslint-disable-next-line
export const AppDataProvider = (props: any) => {
    const { children } = props;
    const [balance, setBalance] = useState(props.balance || initialState.balance);
    const [curBalance, setCurBalance] = useState(props.curBalance || initialState.curBalance);
    const [betList, setBetList] = useState<BetListItem[]>(props.betList || initialState.betList);
    const [userBetList, setUserBetList] = useState<BetListItem[]>(props.userBetList || initialState.userBetList);
    const [bigRollers, setBigRollers] = useState<BetListItem[]>(props.bigRollers || initialState.bigRollers);
    const [winners, setWinners] = useState<BetUserItem[]>(props.winners || initialState.winners);
    const [visibleMobileMenu, setVisibleMobileMenu] = useState<boolean>(props.visibleMobileMenu || initialState.visibleMobileMenu);
    const [gameResult, setGameResult] = useState<IGameResult>(props.gameResult || initialState.gameResult);
    const [newSeed, setNewSeed] = useState<INewSeed>(props.newSeed || initialState.newSeed);

    const handleUpdateCurBalance = (value: number ) => {
        setCurBalance(value)
    }

    const handleUpdateBalance = (data = {}) => {
        setBalance({...balance, ...data})
    }

    const handleInitialize = () => {
        setBalance(initialState.balance);
    }

    const handleBetList = (item: BetListItem | BetListItem[], reset: boolean = false) => {
        if (!reset) {
            if (Array.isArray(item)) setBetList((v) => [...item, ...v])
            else setBetList((v) => [item, ...v])
        } else {
            // eslint-disable-next-line
            // @ts-ignore
            setBetList(item);
        }
    }

    const handleUserBetList = (item: BetListItem | BetListItem[], reset: boolean = false) => {
        if (!reset) {
            if (Array.isArray(item)) setUserBetList((v) => [...item, ...v])
            else setUserBetList((v) => [item, ...v])
        } else {
            // eslint-disable-next-line
            // @ts-ignore
            setUserBetList(item);
        }
    }

    const handleBigRollers = (item: BetListItem | BetListItem[]) => {
        if (Array.isArray(item)) setBigRollers((v) => [...item, ...v].sort((a,b) => b.ball - a.ball));
        else setBigRollers((v) => [item, ...v].sort((a,b) => b.ball - a.ball));
    }

    const handleWinners = (data: BetUserItem[]) => {
        setWinners(data)
    }

    const handleVisibleMobileMenu = (status: boolean) => {
        setVisibleMobileMenu(status);
    }

    const handleUpdateGameResult = (data: IGameResult) => {
        setGameResult(v => ({ ...v, ...data }));
    }

    const handleUpdateNewSeed = (data: INewSeed) => {
        setNewSeed(data);
    }
    
    return (
        <AppContext.Provider
            value={{
                balance,
                curBalance,
                betList,
                userBetList,
                bigRollers,
                winners,
                visibleMobileMenu,
                gameResult,
                newSeed,

                updateCurBalance: handleUpdateCurBalance,
                updateBalance: handleUpdateBalance,
                updateBetList: handleBetList,
                updateUserBetList: handleUserBetList,
                updateBigRollers: handleBigRollers,
                updateWinners: handleWinners,
                initialize: handleInitialize,
                updateVisibleMobileMenu: handleVisibleMobileMenu,
                updateGameResult: handleUpdateGameResult,
                updateNewSeed: handleUpdateNewSeed,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext