import React, { FC, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import CommonLayout from '../../layouts/CommonLayout';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import Textarea from '../../components/Textarea';
import mailIcon from '../../assets/icons/mail.svg';
import newsletterImg from '../../assets/icons/undraw_Newsletter_re_wrob.svg'
import Button from '../../components/Button';

interface TouchForm {
  name: string;
  email: string;
  comment: string;
}

const Contact: FC = () => {
  const [info, setInfo] = useState<TouchForm>({} as TouchForm);

  const handleChange = (field: string, value: string) => {
    setInfo(v => ({ ...v, [field]: value }));
  }

  const handleSubmit = () => {}

  return (
    <CommonLayout
      disableSidebar
      enableFooter
      classes='grid grid-cols-1 xl:grid-cols-2 w-full max-w-[900px] px-6 xl:px-16 py-7 xl:py-13 mx-auto my-20 xl:my-32 gap-4 bg-pink2'
    >
      <div className='flex flex-col gap-6'>
        <Text type='title-yellow' classes='font-bold' label='Contact us' />
        <Text type='dark' label="If you have got any questions please send us an email below OR Fill in this Contact form and we'll respond as soon as possible" />
        <a href="mailto:casine@casino.com" className='flex flex-row items-center gap-1'>
          <img src={mailIcon} />
          <Text type='white' classes='font-medium' label='casine@casino.com' />
        </a>
        <img src={newsletterImg} className='w-72 my-5 md:my-10' />
      </div>
      <div className='flex flex-col gap-8'>
        <Text type='title-yellow' classes='font-bold' label='Get in Touch' />
        <TextInput
          placeholder='Enter your Name'
          enableLabel={false}
          inputContainerClasses='border-white px-5 py-3 rounded'
          value={info.name}
          onChange={e => handleChange('name', e.target.value)}
          />
        <TextInput
          placeholder='Enter your Email'
          enableLabel={false}
          inputContainerClasses='border-white px-5 py-3 rounded'
          value={info.email}
          onChange={e => handleChange('email', e.target.value)}
        />
        <Textarea
          classes='border-white px-5 py-3 rounded h-28'
          placeholder='Enter Comments'
          value={info.comment}
          onChange={e => handleChange('comment', e)}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY || ''}
          theme='dark'
          className='mx-auto'
        />
        <Button classes='text-center font-medium py-2.5' label='Submit' onAction={handleSubmit} />
      </div>
    </CommonLayout>
  )
}

export default Contact;
