import React, { FC } from 'react';

import chipIcon from '../../../assets/icons/chip_back.svg';

interface ChipItemProps {
  value: number;
  label: string;
  bg: string;
  active?: boolean;
  cat?: boolean;
  classes?: string;
  labelClasses?: string;
  onSelect?: (value: number) => void;
}

const ChipItem: FC<ChipItemProps> = (props) => {
  const {
    value,
    label,
    bg,
    active = false,
    cat = false,
    classes = '',
    labelClasses = '',
    onSelect = () => {},
  } = props;

  return (
    <div
      className={`
        ${bg} rounded-full relative flex items-center justify-center
        ${cat ?
          `
            border-2 sm:border-4
            ${active ?
              'border-red-800 w-[30px] h-[30px] sm:w-[46px] sm:h-[46px] lg:w-[58px] lg:h-[58px] m-0 sm:m-0.5'
              :
              'border-gray1 w-[26px] h-[26px] sm:w-[38px] sm:h-[38px] lg:w-[52px] lg:h-[52px] m-0.5 sm:m-1.5'
            }
          `
          :
          'border-2 border-transparent w-14 h-14 m-1'
        }
        ${classes}
      `}
      onClick={() => onSelect(value)}
    >
      <img src={chipIcon} alt='chip' className='absolute top-0 bottom-0 left-0 right-0' />
      <span
        className={`w-4 h-4 sm:w-6 sm:h-6 lg:w-8 lg:h-8 bg-white text-dark1 text-[8px] sm:text-[10px] lg:text-sm font-medium !rounded-full flex items-center justify-center ${labelClasses}`}
      >
        {label}
      </span>
    </div>
  )
}

export default ChipItem;
