import React, { FC, useEffect, useState } from 'react';
import ChipItem from './ChipItem';
import { ChipItemType, RouletteBetItem } from '../../../helper/types';
import { getChipItem } from '../../../helper/functions';

interface NumItemProp {
  label: number;
  active: boolean;
  betList: RouletteBetItem[];
  rollout: boolean;
  classes?: string;
  onHoverAction: (status: string) => void;
  onLeaveAction: () => void;
  // eslint-disable-next-line
  onAction: (area: string, e: any) => void;
}

const redList = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];

const NumItem: FC<NumItemProp> = props => {
  const {
    label,
    active,
    betList,
    rollout,
    classes = '',
    onHoverAction,
    onLeaveAction,
    onAction,
  } = props;

  const [betInfo, setBetInfo] = useState<ChipItemType | null>(null);
  const [TLInfo, setTLInfo] = useState<ChipItemType | null>(null);
  const [TMInfo, setTMInfo] = useState<ChipItemType | null>(null);
  const [TRInfo, setTRInfo] = useState<ChipItemType | null>(null);
  const [MLInfo, setMLInfo] = useState<ChipItemType | null>(null);
  const [MRInfo, setMRInfo] = useState<ChipItemType | null>(null);
  // eslint-disable-next-line
  const [BLInfo, setBLInfo] = useState<ChipItemType | null>(null);
  // eslint-disable-next-line
  const [BMInfo, setBMInfo] = useState<ChipItemType | null>(null);
  // eslint-disable-next-line
  const [BRInfo, setBRInfo] = useState<ChipItemType | null>(null);

  const [hover, setHover] = useState<boolean>(false);

  useEffect(() => {
    let betItem = betList.find(item => item.area === (label).toString())
    if (betItem) {
      setBetInfo(getChipItem(betItem.betAmount))
    } else {
      setBetInfo(null)
    }

    // betItem = betList.find(item => (item.area === `t-${label - 1}` || item.area === `m-${label - 1}`))
    betItem = betList.find(item => item.area === `m-${label - 1}`)
    if (betItem) {
      setTLInfo(getChipItem(betItem.betAmount))
    } else {
      setTLInfo(null)
    }

    // betItem = betList.find(item => (item.area === `t-${label + 0.5}` || item.area === `v-${label + 0.5}`))
    betItem = betList.find(item => item.area === `v-${label + 0.5}`)
    if (betItem) {
      setTMInfo(getChipItem(betItem.betAmount))
    } else {
      setTMInfo(null)
    }

    // betItem = betList.find(item => (item.area === `t-${label + 2}` || item.area === `m-${label + 2}`))
    betItem = betList.find(item => item.area === `m-${label + 2}`)
    if (betItem) {
      setTRInfo(getChipItem(betItem.betAmount))
    } else {
      setTRInfo(null)
    }

    betItem = betList.find(item => (item.area === `h-${label - 1.5}`))
    if (betItem) {
      console.log('betItem : ML: ', label, label-1.5, betItem)
      setMLInfo(getChipItem(betItem.betAmount))
    } else {
      setMLInfo(null)
    }

    betItem = betList.find(item => (item.area === `h-${label + 1.5}`))
    if (betItem) {
      console.log('betItem : MR: ', label, label+1.5, betItem)
      setMRInfo(getChipItem(betItem.betAmount))
    } else {
      setMRInfo(null)
    }

    betItem = betList.find(item => (item.area === `b-${label - 2}` || item.area === `m-${label - 2}`))
    if (betItem) {
      setBLInfo(getChipItem(betItem.betAmount))
    } else {
      setBLInfo(null)
    }

    betItem = betList.find(item => (item.area === `b-${label - 0.5}` || item.area === `v-${label - 0.5}`))
    if (betItem) {
      setBMInfo(getChipItem(betItem.betAmount))
    } else {
      setBMInfo(null)
    }

    betItem = betList.find(item => (item.area === `b-${label + 1}` || item.area === `m-${label + 1}`))
    if (betItem) {
      setBRInfo(getChipItem(betItem.betAmount))
    } else {
      setBRInfo(null)
    }
  }, [betList])

  return (
    <div
      className={`relative ${classes} ${rollout && 'border rounded'} w-max`}
    >
      <span
        className={`
          flex items-center justify-center border w-5 h-5 sm:w-8 sm:h-8 lg:w-12 lg:h-12 text-white text-[10px] sm:text-sm lg:text-base
          ${active || hover ?
            `${redList.includes(label) ? 'bg-red-600' : 'bg-gray-600'} active`
            :
            redList.includes(label) ? 'bg-red-800 hover:bg-red-600' : 'bg-gray-800 hover:bg-gray-600'
          }
          ${rollout && 'w-[30px] h-[30px] lg:w-[46px] lg:h-[46px]' }
        `}
      >
        {label}
      </span>
      <div>
        <div
          className='absolute sm:-left-0.5 lg:-left-1 sm:-top-0.5 lg:-top-1 sm:w-2.5 sm:h-2.5 lg:w-[16px] lg:h-4'
          onMouseDown={(e) => onAction(`${label % 3 === 0 ? 't' : 'm'}-${label - 1}`, e)}
          onMouseEnter={() => label % 3 !== 0 && onHoverAction(`m-${label-1}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {TLInfo &&
            <ChipItem
              {...TLInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-top-[10px] sm:-left-[10px] lg:-top-[16px] lg:-left-[14px] sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:left-2 lg:left-3 sm:-top-0.5 lg:-top-1 sm:w-4 sm:h-2.5 lg:w-6 lg:h-4'
          onMouseDown={(e) => onAction(`${label % 3 === 0 ? 't' : 'v'}-${label + 0.5}`, e)}
          onMouseEnter={() => label % 3 !== 0 && onHoverAction(`v-${label+0.5}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {TMInfo &&
            <ChipItem
              {...TMInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-top-[10px] sm:-left-[1px] lg:-top-4 lg:-left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:-right-0.5 sm:-top-0.5 lg:-right-1 lg:-top-1 sm:w-2.5 sm:h-2.5 lg:w-4 lg:h-4'
          onMouseDown={(e) => {
            if (label > 33)
              onAction(`${label % 3 === 0 ? label.toString() : `v-${label + 0.5}`}`, e)
            else
              onAction(`${label % 3 === 0 ? 't' : 'm'}-${label + 2}`, e)
          }}
          onMouseEnter={() => label % 3 !== 0 && onHoverAction(`m-${label+2}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {TRInfo &&
            <ChipItem
              {...TRInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-top-[10px] sm:left-0.5 lg:-top-[16px] lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>

        <div
          className='absolute sm:-left-0.5 sm:top-2 lg:-left-1 lg:top-3 sm:w-2.5 sm:h-4 lg:w-4 lg:h-6'
          onMouseDown={(e) => onAction(`h-${label - 1.5}`, e)}
          onMouseEnter={() => onHoverAction(`h-${label-1.5}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {MLInfo &&
            <ChipItem
              {...MLInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-left-[10px] sm:-top-0.5 lg:-left-[14px] lg:-top-1 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute left-0 top-0 sm:left-2 sm:top-2 lg:left-3 lg:top-3 w-5 h-5 sm:w-4 sm:h-4 lg:w-6 lg:h-6'
          onMouseDown={(e) => {onAction((label).toString(), e);console.log('betInfo')}}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {betInfo &&
            <ChipItem
              {...betInfo}
              classes='!w-4 !h-4 sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] -left-0.5 -top-0.5 sm:-left-[1px] sm:-top-0.5 lg:-left-0.5 lg:-top-0.5 sm:!border-0 sm:!m-0'
              labelClasses='!w-[10px] !h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] !text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:-right-0.5 sm:-right-1 sm:top-2 lg:top-3 sm:w-2.5 sm:h-4 lg:w-4 lg:h-6'
          onMouseDown={(e) => {
            if (label > 33)
              onAction(label.toString(), e)
            else
              onAction(`h-${label + 1.5}`, e)
          }}
          onMouseEnter={() => onHoverAction(`h-${label+1.5}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {MRInfo &&
            <ChipItem
              {...MRInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-right-0.5 sm:-top-0.5 lg:-right-0.5 lg:-top-1 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>

        <div
          className={`
            absolute sm:-left-0.5 lg:-left-1 sm:w-2.5 lg:w-4
            ${label % 3 === 1 ? 'sm:-bottom-1 lg:-bottom-2 sm:h-3 lg:h-5' : 'sm:-bottom-0.5 lg:-bottom-1 sm:h-2.5 lg:h-4'}
          `}
          onMouseDown={(e) => onAction(`${label % 3 === 1 ? 'b' : 'm'}-${label - 2}`, e)}
          onMouseEnter={() => onHoverAction(`${label % 3 === 1 ? 'b' : 'm'}-${label-2}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {BLInfo &&
            <ChipItem
              {...BLInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:bottom-0 sm:-left-[10px] lg:bottom-0 lg:-left-[14px] sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className={`
            absolute sm:left-2 lg:left-3 sm:w-4 lg:w-6
            ${label % 3 === 1 ? 'sm:-bottom-1 lg:-bottom-2 sm:h-3 lg:h-5' : 'sm:-bottom-0.5 lg:-bottom-1 sm:h-2.5 lg:h-4'}
          `}
          onMouseDown={(e) => onAction(`${label % 3 === 1 ? 'b' : 'v'}-${label - 0.5}`, e)}
          onMouseEnter={() => onHoverAction(`${label % 3 === 1 ? 'b' : 'v'}-${label-0.5}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {BMInfo &&
            <ChipItem
              {...BMInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-bottom-0 sm:-left-0.5 lg:-bottom-0 lg:-left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className={`
            absolute sm:-right-0.5 lg:-right-1 sm:w-2.5 lg:w-4
            ${label % 3 === 1 ? 'sm:-bottom-1 lg:-bottom-2 sm:h-3 lg:h-5' : 'sm:-bottom-0.5 lg:-bottom-1 sm:h-2.5 lg:h-4'}
          `}
          onMouseDown={(e) => {
            if (label > 33)
              onAction(`${label % 3 === 1 ? 'b' : 'v'}-${label - 0.5}`, e)
            else
              onAction(`${label % 3 === 1 ? 'b' : 'm'}-${label + 1}`, e)
          }}
          onMouseEnter={() => onHoverAction(`${label % 3 === 1 ? 'b' : 'm'}-${label+1}`)}
          onMouseLeave={() => onLeaveAction()}
        >
          {BRInfo &&
            <ChipItem
              {...BRInfo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:bottom-0 sm:left-0.5 lg:-bottom-0 lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
      </div>
    </div>
  )
}

export default NumItem;
