import React, { FC } from 'react';

interface CustomButtonProps {
  classes?: string;
  bg?: 'yellow' | 'dark' | 'green' | 'red';
  // eslint-disable-next-line
  children?: any;
  onAction: () => void;
}

const CustomButton: FC<CustomButtonProps> = props => {
  const {
    bg = 'dark',
    classes = '',
    children,
    onAction = () => {}
  } = props;

  const bgColor = () => {
    switch (bg) {
      case 'yellow':
        return 'bg-yellow2'
      case 'dark':
        return 'bg-gray2'
      case 'green':
        return 'bg-green-500'
      case 'red':
        return 'bg-red-500'
      default:
        break;
    }
  }

  return (
    <div
      className={`
        rounded text-white cursor-pointer px-3 py-1.5
        ${bgColor()}
        ${classes}
      `}
      onClick={() => onAction()}
    >
      {children}
    </div>
  )
}

export default CustomButton;
