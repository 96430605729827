import React, { FC } from 'react';
import Text from '../Text';

interface CardProps {
  // eslint-disable-next-line
  icon?: any;
  title?: string;
  // eslint-disable-next-line
  children?: any;
  classes?: string;
  contentClasses?: string;
}

const Card: FC<CardProps> = props => {
  const {
    icon = null,
    title = '',
    children = null,
    classes = '',
    contentClasses = '',
  } = props;

  return (
    <div className={`flex flex-col bg-dark3 rounded-lg ${classes}`}>
      <div className='flex flex-row px-4 py-3 gap-2 border-b border-gray-600'>
        <img src={icon} />
        <Text type='white' label={title} />
      </div>
      <div className={`${contentClasses}`}>
        {children}
      </div>
    </div>
  )
}

export default Card;
