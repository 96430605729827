import React, { FC, useEffect } from 'react';
import * as Types from '../helper/types';
import { Link, useLocation } from 'react-router-dom';
import Image from '../components/Image';
import Text from '../components/Text';
import { LEFT_SIDEBAR } from '../helper/constants';
import useAppData from '../hooks/useAppData';

interface LeftSidebarProps {
  classes?: string;
}

interface SidebarItemProps extends Types.SidebarItem {
  active: boolean;
}

const SidebarItem = ({link, icon, label, active=false}: SidebarItemProps) => (
  <Link to={link} className={`sidebar-item flex flex-row items-center px-9 py-3 gap-5 z-10 ${active && 'active'}`}>
    <Image src={icon} classes='max-h-8' />
    <Text label={label} classes='whitespace-nowrap' />
  </Link>
);

const LeftSidebar: FC<LeftSidebarProps> = ({ classes = '' }) => {
  const location = useLocation();
  const { visibleMobileMenu, updateVisibleMobileMenu } = useAppData();

  useEffect(() => {
    console.log('location: ', location.pathname);
    updateVisibleMobileMenu(false)
  }, [location])

  return (
    <div
      className={`
        left-sidebar flex flex-col absolute md:relative py-8 gap-0.5 min-w-[0px] transition-opacity, duration-500
        ${classes} ${visibleMobileMenu ? 'left-0 top-0 h-full z-10 w-3/5 z-40' : 'opacity-0 md:opacity-100'}
      `}
    >
      {LEFT_SIDEBAR.map((item, index) => (
        <SidebarItem key={index} {...item} active={location.pathname === item.link} />
      ))}
    </div>
  )
}

export default LeftSidebar;
