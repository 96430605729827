import React, { FC, useEffect } from 'react';

import { BetUserItem } from '../../helper/types';

interface BetUsersTableProps {
  data: BetUserItem[]
}

const BetUsersTable: FC<BetUsersTableProps> = props => {
  const { data } = props;
  console.log('data list: : ', data)

  useEffect(() => {
    console.log('userTabel data:', data);
  }, [data]);

  return (
    <div className='overflow-auto'>
      <table className='data-table w-full text-center'>
        <thead className='border-b border-gray2'>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>#</th>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>User name</th>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>Games</th>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>Profit</th>
        </thead>
        <tbody>
          {data && data.length > 0 && data.map((item, index) => (
            <tr key={index} className='even:bg-dark1'>
              <td className='text-white text-sm px-3 py-4'>{index}</td>
              <td className='text-white text-sm px-3 py-4'>{item.username}</td>
              <td className='text-white text-sm px-3 py-4'>{item.gameCnt}</td>
              <td className='text-white text-sm px-3 py-4'>{item.profit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default BetUsersTable;
