import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoSettingsSharp } from 'react-icons/io5'
import { RiMenu3Fill } from 'react-icons/ri'
import { AiOutlineClose } from 'react-icons/ai'

import { APP_ROUTES } from '../helper/constants';
import Image from '../components/Image';
import Text from '../components/Text';
import Dropdown from '../components/Dropdown';
import useAuth from '../hooks/useAuth';
import useAppData from '../hooks/useAppData';

import logoImg from '../assets/images/logo.png';
import bitcoinIcon from '../assets/icons/bitcoin (2).svg';
import userIcon from '../assets/icons/user-icon.svg';
import powerIcon from '../assets/icons/power-icon.svg';
import downIcon from '../assets/icons/down-arrow (3).svg'
import scrollIcon from '../assets/icons/scroll-icon.svg'
import homeIcon from '../assets/icons/home.svg'
import trophyIcon from '../assets/icons/trophy.svg'
import walletIcon from '../assets/icons/wallet.svg'
import phoneIcon from '../assets/icons/phone-icon.svg'

import btcIcon from '../assets/icons/clogo/btc.svg';
import ethIcon from '../assets/icons/clogo/eth.svg';
import bchIcon from '../assets/icons/clogo/bch.svg';
import ltcIcon from '../assets/icons/clogo/ltc.svg';
import dogeIcon from '../assets/icons/clogo/doge.svg';
import htIcon from '../assets/icons/clogo/ht.svg';

interface HeaderProps {
  classes?: string;
}

type NavbarItemType = {
  link: string;
  label: string;
}

type CoinItemType = {
  value: string;
  // eslint-disable-next-line
  icon: any;
}

const NavbarItem = ({ link, label }: NavbarItemType) => (
  <Link to={link} className='px-4'>
    <Text type='white' label={label} />
  </Link>
)

const Header: FC<HeaderProps> = props => {
  const { classes = '' } = props;
  const { isAuthenticated, logout } = useAuth();
  const { balance, curBalance, visibleMobileMenu, updateCurBalance, updateBalance, updateVisibleMobileMenu } = useAppData();

  const [coinList] = useState<CoinItemType[]>([
    { value: 'btc', icon: btcIcon },
    { value: 'eth', icon: ethIcon },
    { value: 'bch', icon: bchIcon },
    { value: 'ltc', icon: ltcIcon },
    { value: 'doge', icon: dogeIcon },
    { value: 'ht', icon: htIcon },
  ]);
  const [coin, setCoin] = useState<CoinItemType>(coinList[0])
  const [cBalance, setCBalance] = useState<number>(0)

  useEffect(() => {
    updateBalance({ [coin.value]: curBalance });
    setCBalance(curBalance);
    console.log('curBalance change: ', curBalance);
  }, [curBalance])

  const handleChangeCoin = (coinItem: CoinItemType) => {
    setCoin(coinItem);
    // eslint-disable-next-line
    if (balance.hasOwnProperty(coinItem.value)) {
      // eslint-disable-next-line
      // @ts-ignore
      updateCurBalance(balance[coinItem.value])
    } else {
      updateCurBalance(0)
    }
  }

  return (
    <div className={`bg-pink1 ${classes}`}>
      <div className={`flex flex-row justify-between py-5 px-4 bg-pink1`}>
        <div className='flex flex-row items-center gap-4'>
          <Link to='/'>
            <Image src={logoImg} width={126} />
          </Link>
          <div
            className='block md:hidden text-white text-4xl'
            onClick={() => updateVisibleMobileMenu(!visibleMobileMenu)}
          >
            {visibleMobileMenu ?
              <AiOutlineClose />
              :
              <RiMenu3Fill />
            }
          </div>
        </div>
        <div className='hidden md:flex flex-row items-center'>
          <Dropdown
            classes='mr-5 cursor-pointer'
            subicon={
              <div className='flex flex-row items-center'>
                <img src={coin.icon} width={16} className='mr-2' />
                <img src={scrollIcon} />
              </div>
            }
            align='left'
            label={cBalance.toFixed(8)}
          >
            {coinList.map((item, index) => (
              <div
                key={index}
                className='flex flex-row items-center'
                onClick={() => handleChangeCoin(item)}
              >
                <Text
                  type='lg-white'
                  // eslint-disable-next-line
                  // @ts-ignore
                  // eslint-disable-next-line
                  label={balance.hasOwnProperty(item.value) ? balance[item.value].toFixed(8) : parseInt('0').toFixed(8)}
                  classes='font-medium w-28'
                />
                <div className='flex bg-white rounded-full w-6'>
                  <img src={item.icon} className='w-6 h-6 rounded-full' />
                </div>
              </div>
            ))}
          </Dropdown>
          <NavbarItem link={APP_ROUTES.CASINO} label='CASINO' />
          <NavbarItem link={APP_ROUTES.WINNERS} label='WINNERS' />
          <NavbarItem link={APP_ROUTES.CONTACT} label='CONTACT US' />
          <Dropdown
            classes='ml-5 cursor-pointer'
            icon={<img src={userIcon} />}
            subicon={<img src={downIcon} />}
            label='ACCOUNT'
          >
            {isAuthenticated ?
              <>
                <Link to='#' className='flex flex-row items-center gap-2'>
                  <img src={bitcoinIcon} width={30} />
                  <Text type='white' classes='font-bold' label='FREE BTC!' />
                </Link>
                <Link to={APP_ROUTES.SETTING} className='flex flex-row items-center gap-2'>
                  <IoSettingsSharp className='text-white text-3xl' />
                  <Text type='white' classes='font-bold' label='SETTINGS' />
                </Link>
                <div
                  className='flex flex-row items-center gap-2 cursor-pointer'
                  onClick={() => logout()}
                >
                  <img src={powerIcon} width={30} />
                  <Text type='white' classes='font-bold' label='LOGOUT' />
                </div>
              </>
              :
              <>
                <Link to='#' className='flex flex-row items-center gap-2'>
                  <img src={userIcon} width={26} />
                  <Text type='white' classes='font-bold' label='LOGIN' />
                </Link>
                <Link to='#' className='flex flex-row items-center gap-2'>
                  <img src={userIcon} width={26} />
                  <Text type='white' classes='font-bold' label='REGISTER' />
                </Link>
              </>
            }
          </Dropdown>
        </div>
        <div className='flex flex-col md:hidden'>
          <Dropdown
            classes='ml-5 cursor-pointer'
            icon={<img src={userIcon} />}
            subicon={<img src={downIcon} />}
            label=''
          >
            <Link to='/' className='flex flex-row items-center gap-2'>
              <img src={homeIcon} width={30} />
              <Text type='white' classes='font-bold' label='HOME' />
            </Link>
            <Link to={APP_ROUTES.WINNERS} className='flex flex-row items-center gap-2'>
              <img src={trophyIcon} width={30} />
              <Text type='white' classes='font-bold' label='WINNERS' />
            </Link>
            {isAuthenticated ?
              <>
                <Link to='#' className='flex flex-row items-center gap-2'>
                  <img src={walletIcon} width={30} />
                  <Text type='white' classes='font-bold' label='WALLET' />
                </Link>
                <Link to='#' className='flex flex-row items-center gap-2'>
                  <img src={bitcoinIcon} width={30} />
                  <Text type='white' classes='font-bold' label='FREE BTC!' />
                </Link>
                <Link to={APP_ROUTES.CONTACT} className='flex flex-row items-center gap-2'>
                  <img src={phoneIcon} width={30} />
                  <Text type='white' classes='font-bold' label='CONTACT US' />
                </Link>
                <Link to={APP_ROUTES.SETTING} className='flex flex-row items-center gap-2'>
                  <IoSettingsSharp className='text-white text-3xl' />
                  <Text type='white' classes='font-bold' label='SETTINGS' />
                </Link>
                <div
                  className='flex flex-row items-center gap-2 cursor-pointer'
                  onClick={() => logout()}
                >
                  <img src={powerIcon} width={30} />
                  <Text type='white' classes='font-bold' label='LOGOUT' />
                </div>
              </>
              :
              <>
                <Link to='#' className='flex flex-row items-center gap-2'>
                  <img src={userIcon} width={26} />
                  <Text type='white' classes='font-bold' label='LOGIN' />
                </Link>
                <Link to='#' className='flex flex-row items-center gap-2'>
                  <img src={userIcon} width={26} />
                  <Text type='white' classes='font-bold' label='REGISTER' />
                </Link>
              </>
            }
          </Dropdown>

        </div>
      </div>
      <div className='flex md:hidden justify-center bg-pink1 pb-5'>
        <Dropdown
          classes='mr-5 cursor-pointer'
          subicon={
            <div className='flex flex-row items-center'>
              <img src={coin.icon} width={16} className='mr-2' />
              <img src={scrollIcon} />
            </div>
          }
          align='left'
          label={cBalance.toFixed(8)}
        >
          {coinList.map((item, index) => (
            <div
              key={index}
              className='flex flex-row items-center'
              onClick={() => handleChangeCoin(item)}
            >
              <Text
                type='lg-white'
                // eslint-disable-next-line
                // @ts-ignore
                // eslint-disable-next-line
                label={balance.hasOwnProperty(item.value) ? balance[item.value].toFixed(8) : parseInt('0').toFixed(8)}
                classes='font-medium w-28'
              />
              <div className='flex bg-white rounded-full w-6'>
                <img src={item.icon} className='w-6 h-6 rounded-full' />
              </div>
            </div>
          ))}
        </Dropdown>
      </div>
    </div>
  )
}

export default Header;
