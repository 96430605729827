import React, { createContext, useReducer } from "react";
import jwtDecode from "jwt-decode"
import { PayloadAction } from "@reduxjs/toolkit";
import sha256 from 'crypto-js/sha256';
import AxiosInstance from "../services/apiClient"

interface AuthStateProps {
    isAuthenticated?: boolean;
    isInitialised?: boolean;
    // eslint-disable-next-line
    user?: any;
}

interface AuthContextProps extends AuthStateProps {
    method?: string;
    // eslint-disable-next-line
    login: (email: string, password: string) => Promise<any>;
    // eslint-disable-next-line
    loginFree: (username: string) => Promise<any>;
    logout: () => void;
    // eslint-disable-next-line
    register: (data: any) => Promise<any>;
    // eslint-disable-next-line
    registerFree: () => Promise<any>;
}

const initialState = {
    isAuthenticated: false,
    isInitalised: false,
    user: null
}

// eslint-disable-next-line
const inValidToken = (accessToken: string) => {
    if (!accessToken) {
        return false
    }

    // eslint-disable-next-line
    const decodedToken: any = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken: string) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        AxiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete AxiosInstance.defaults.headers.common.Authorization
    }
}

const reducer = (state: AuthStateProps, action: PayloadAction<AuthStateProps>) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext<AuthContextProps>({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    loginFree: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
    registerFree: () => Promise.resolve(),
})

// eslint-disable-next-line
export const AuthProvider = (props: any) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email: string, password: string) => {
        const response = await AxiosInstance.post('/auth/login', {
            email,
            password,
        })

        if (response.data.$success) {
            const { accessToken, user } = response.data
    
            setSession(accessToken)
    
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
        }
    }

    const loginFree = async (username: string) => {
        const response = await AxiosInstance.post('auth/login/free', {
            username
        })

        if (response.data.$success) {
            const { accessToken, user: { balance, serverSeed, ...rest } } = response.data
    
            setSession(accessToken)
            let playerSeed = localStorage.getItem('playerSeed');
            if (!playerSeed) {
                playerSeed = sha256(rest.username).toString();
                localStorage.setItem('playerSeed', playerSeed);
            }

            dispatch({
                type: 'LOGIN',
                payload: {
                    user: { ...rest, playerSeed },
                },
            })
            return { balance, seedInfo: { playerSeed, serverSeed } };
        } else {
            return false
        }
    }

    // eslint-disable-next-line
    const register = async (data: any) => {
        const response = await AxiosInstance.post('/auth/register', data)

        if (response.data.$success) {
            const { accessToken, user: { balance, ...rest } } = response.data
    
            setSession(accessToken)
    
            dispatch({
                type: 'REGISTER',
                payload: {
                    user: rest,
                },
            })

            return balance;
        } else {
            return false;
        }
    }

    // eslint-disable-next-line
    const registerFree = async () => {
        const response = await AxiosInstance.post('auth/register/free');

        if (response.data.$success) {
            const { accessToken, user: { balance, serverSeed, ...rest } } = response.data;
    
            setSession(accessToken);
            localStorage.setItem('username', rest.username);
            const playerSeed = sha256(rest.username).toString();
            localStorage.setItem('playerSeed', playerSeed);
    
            dispatch({
                type: 'REGISTER',
                payload: {
                    user: { ...rest, playerSeed },
                },
            })
            
            return { balance, seedInfo: { playerSeed, serverSeed }};
        } else {
            return false;
        }

    }

    const logout = () => {
        setSession('')
        dispatch({ type: 'LOGOUT', payload: {} })
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                loginFree,
                logout,
                register,
                registerFree,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
