import React, { FC } from 'react';

interface IconButtonProps {
  classes?: string;
  bg?: 'green' | 'orange';
  label?: string;
  // eslint-disable-next-line
  children: any;
  onAction: () => void;
}

const IconButton: FC<IconButtonProps> = props => {
  const {
    bg = 'orange',
    classes = '',
    children,
    onAction = () => {}
  } = props;

  return (
    <div
      className={`
        rounded text-white cursor-pointer px-3 py-2
        ${bg === 'green' ? 'bg-green1' : bg === 'orange' ? 'bg-yellow2' : ''}
        ${classes}
      `}
      onClick={() => onAction()}
    >
      {children}
    </div>
  )
}

export default IconButton;
