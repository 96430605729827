import React, { FC, useEffect, useState } from 'react';

import TextInput from '../../TextInput';
import Button from '../../Button';
import { BallStep, ColorCalValueList, ColorList, ColorValueList, PREV_GAME, PlinkoBallLimit } from '../../../helper/constants';
import Ball from './Ball';
import { BallItem, BetListItem, PlinkoInit } from '../../../helper/types';
import WhiteBall from './WhiteBall';
import useAppData from '../../../hooks/useAppData';
import plinkService from '../../../services/plinkService';
import useAuth from '../../../hooks/useAuth';
import { IGameResult } from '../../../context/AppContext';
import Modal from '../../Modal';
import Text from '../../Text';

const Plinko: FC = () => {
  const { user, isAuthenticated } = useAuth();
  const {
    curBalance,
    newSeed,
    updateBetList,
    updateUserBetList,
    updateBigRollers,
    updateWinners,
    updateCurBalance,
    updateGameResult,
  } = useAppData();

  const [betAmount, setBetAmount] = useState<string>('')
  const [ball, setBall] = useState<string>('')
  const [ballList, setBallList] = useState<BallItem[]>([]);
  const [initial, setInitial] = useState<PlinkoInit>({} as PlinkoInit)
  const [seedInfo, setSeedInfo] = useState<IGameResult>({} as IGameResult);

  const [row, setRow] = useState<number>(2);
  const [start, setStart] = useState(false);
  const [activeList, setActiveList] = useState<{offset: number, step: number}[]>([]);
  const [visibleTop, setVisibleTop] = useState<boolean>(true);
  const [newItem, setNewItem] = useState<BetListItem>({} as BetListItem);

  const [fundInfo, setFundInfo] = useState<{balance: number, won: number}>({balance: 0, won: 0})
  const [warnModal, setWarnModal] = useState<boolean>(false);

  useEffect(() => {
    setInitial({
      offsetX: window.innerWidth < 576 ? 8 : 15,
      offsetY: window.innerWidth < 576 ? 12 : 26,
      genPeriod: 30,
      stepPeriod: 12,
      whiteSize: 2,
      whiteGap: 3
    })
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      plinkService.getList()
        .then(res => {
          if (res.$success) {
            updateBetList(res.data.betList);
            updateUserBetList(res.data.userBetList);
            updateBigRollers(res.data.bigRollers);
            updateWinners(res.data.winnerList);
          }
        })
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (start && ballList.length > 0) {
      if (ballList[ballList.length - 1].step - (ballList.length - 1) * initial.genPeriod < ballList[ballList.length - 1].value.length * initial.genPeriod) {
        let tmp_val = -1;
        setTimeout(() => {
          setBallList(v => v.map((item, index) => {
            if (
              (item.step - index * initial.genPeriod) >= 0
              && (item.step - index * initial.genPeriod) % initial.stepPeriod === 0
              && ((item.step - index * initial.genPeriod) / initial.stepPeriod) <= item.value.length
            ) {
              const offset = (item.step - index * initial.genPeriod) / initial.stepPeriod;
              const curVal = item.value.slice(offset, offset+1);
              if (((item.step - index * initial.genPeriod) / initial.stepPeriod) < item.value.length) {
                if (curVal === '0') return { ...item, offsetX: item.offsetX - initial.offsetX, offsetY: item.offsetY + initial.offsetY, step: item.step + 1 }
                else return { ...item, offsetX: item.offsetX + initial.offsetX, offsetY: item.offsetY + initial.offsetY, step: item.step + 1 }
              } else {
                return { ...item, offsetY: item.offsetY + initial.offsetY, step: item.step + 1 }
              }
            } else {
              if (((item.step - index * initial.genPeriod) / initial.stepPeriod) === item.value.length + 1) {
                tmp_val ++;
                if (tmp_val > 0) return { ...item, step: item.step + 1 };
                console.log('score amount info before: ', fundInfo.balance, fundInfo.won, index, tmp_val)
                const valStr = item.value.split('');
                const zeroCnt = valStr.filter(item => item === '0').length;
                const oneCnt = item.value.length - zeroCnt;
                let offsetList: number[] = [];
                if (activeList.length > 0) offsetList = activeList.map(item => item.offset)
                if (!offsetList.includes(8 + (oneCnt - zeroCnt) / 2)) {
                  setActiveList(v => ([...v, { offset: 8 + (oneCnt - zeroCnt) / 2, step: 0 }]))
                }

                const score = ColorCalValueList[ColorList[row]][Math.abs((oneCnt - zeroCnt) / 2)];
                const scoreAmount = score * (betAmount ? parseFloat(betAmount) : 0);
                updateCurBalance(fundInfo.balance + scoreAmount)
                
                console.log('score amount info: ', scoreAmount, fundInfo.balance, fundInfo.won, index, tmp_val)
                setFundInfo(v => ({ ...v, won: v.won + scoreAmount }))

                if (index === ballList.length - 1) {
                  if (seedInfo.result) {
                    updateGameResult(seedInfo);
                  }
                }

                return { ...item, step: item.step + 1, disable: true };
              } else {
                return { ...item, step: item.step + 1 };
              }
            }
          }))
        }, 20)
        if (ballList[ballList.length - 1].step - (ballList.length - 1) * initial.genPeriod > 0) {
          setVisibleTop(false);
        }
      } else {
        setBallList([]);
        setVisibleTop(true);
        setStart(false)
        user && updateBetList({ ...newItem, username: user.username })
        user && updateUserBetList({ ...newItem, username: user.username })
        user && updateBigRollers({ ...newItem, username: user.username })
      }
    } else {
      setStart(false)
      // if (seedInfo.result)
      //   updateGameResult(seedInfo);
    }
  }, [ballList, start, initial.genPeriod, newItem])

  useEffect(() => {
    setFundInfo(v => ({ ...v, balance: curBalance }))
  }, [curBalance])

  useEffect(() => {
    if (activeList.length > 0) {
      setTimeout(() => {
        setActiveList(v => v.filter(item => item.step < 6).map(item => ({ ...item, step: item.step + 1 })));
      }, 100);
    }
  }, [activeList])

  const handleChangeRow = () => {
    if (!start) {
      if (row + 1 > 4) setRow(row + 1 - 5);
      else setRow(row + 1);
    }
  }

  const handleDrop = async () => {
    if (curBalance < parseFloat(betAmount)) {
      setWarnModal(true)
      return;
    }
    if (betAmount && parseFloat(betAmount) < 0) setBetAmount('');
    if (ballList.length > 0 || fundInfo.balance === 0 || !isAuthenticated) return;
    
    const cBetAmount = betAmount && parseFloat(betAmount) > 0 ? parseFloat(betAmount) : 0;
    if (betAmount && parseFloat(betAmount) < 0) setBetAmount('0');
    const cBall = (ball && parseInt(ball) > 0) ? (parseInt(ball) > PlinkoBallLimit ? PlinkoBallLimit : parseInt(ball)) : 1;
    if (cBall === 1 || cBall === PlinkoBallLimit) setBall(cBall.toString());

    if (fundInfo.balance - cBall * cBetAmount < 0 || cBetAmount === 0) return;
    setFundInfo(v => ({ ...v, won: 0 }));
    updateCurBalance(fundInfo.balance - cBall * cBetAmount);
    const res = await plinkService.drop({
      ball: cBall, betAmount: cBetAmount, row: ColorList[row], playerSeed: newSeed.playerSeed,
    })
    if (res.$success && res.plinko) {
      setNewItem(res.plinko);
      const ballRoutes = res.plinko.paths;
      // eslint-disable-next-line
      setBallList(ballRoutes.map((item: any) => ({ value: item, step: 0, offsetX: 0, offsetY: 0 })));
      setSeedInfo( v => ({
        ...v,
        serverSeed: '(To see the server seed, you must request a new server seed)',
        playerSeed: res.plinko.playerSeed,
        result: res.plinko.result,
        // newServerSeedHash: res.plinko.newServerSeedHash,
      }));
      localStorage.setItem(
        PREV_GAME,
        JSON.stringify({
          playerSeed: res.plinko.playerSeed,
          result: res.plinko.result,
          serverSeed: '(To see the server seed, you must request a new server seed)'
        }),
      );
      setStart(v => !v);
    }
  }

  const handleChangeBalls = (value: string) => {
    if (!value) setBall('')
    else if (parseInt(value) <= 50) {
      setBall(value);
    } else {
      setBall(ball);
    }
  }

  return (
    <div className='flex flex-col relative gap-5'>
      <div className='relative w-full'>
        {visibleTop &&
          <Ball bg={ColorList[row]} data={{value: "", step: 0, offsetX: 0, offsetY: 0}} />
        }
        {ballList.map((item, index) => (
          <Ball key={index} bg={ColorList[row]} data={item} />
        ))}
        <div className='flex flex-col h-full relative'>
          {Array(BallStep).fill(0).map((item, index) => (
            <div key={index} className={`w-full flex flex-row justify-center items-center h-3 md:h-[26px]`}>
              {Array(index + 3).fill(0).map((sItem, sIndex) => (
                <WhiteBall key={sIndex} />
              ))}
            </div>
          ))}
          <div className='w-full md:w-max mx-auto bg-pink1'>
            <div className='flex flex-col gap-1'>
              {Object.keys(ColorValueList).map((key, index) => {
                let offsetList: number[] = [];
                if (activeList.length > 0) offsetList = activeList.map(item => item.offset);

                return (
                  <div key={key} className='flex flex-row justify-center items-center gap-1'>
                    <div className={`w-16 text-white py-0.5 text-6 md:text-xs text-center`} style={{backgroundColor: ColorValueList[key]['color']}}>
                      <span>{key}</span>
                    </div>
                    {ColorValueList[key]['values'].map((item: number, sIndex: number) => (
                      <div
                        key={sIndex}
                        className={`
                          w-7 text-white py-0.5 text-6 md:text-xs text-center flex justify-center items-center
                          ${row === index && offsetList.includes(sIndex) && `h-[30px] md:h-11 z-10 ${key === 'Red' ? '-mb-[18px] md:-mb-6' : '-mt-[18px] md:-mt-6'}`}
                        `}
                        style={{backgroundColor: ColorValueList[key]['color']}}
                      >
                        <span className='hidden md:block min-w-[10px]'>{(betAmount && parseFloat(betAmount) > 0 ? parseFloat(betAmount) * item : item).toString().slice(0,5)}</span>
                        <span className='block md:hidden min-w-[10px]'>{(betAmount && parseFloat(betAmount) > 0 ? parseFloat(betAmount) * item : item).toString().slice(0,4)}</span>
                      </div>
                    ))}
                    <div className={`w-16 text-white py-0.5 text-6 md:text-xs text-center whitespace-nowrap`} style={{backgroundColor: ColorValueList[key]['color']}}>
                      <span>{ColorValueList[key]['result']}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='absolute flex flex-row justify-center w-full top-0 gap-20 xl:gap-56'>
            <TextInput
              classes='!gap-0 items-center !w-36'
              labelClasses='text-yellow2 font-medium text-lg'
              inputContainerClasses='bg-[#232732] !p-0 border border-[#4f5463] font-medium'
              inputClasses='text-center text-xs xl:text-lg !w-20 xl:!w-36'
              label='Balance'
              value={fundInfo.balance ? fundInfo.balance.toFixed(4) : '0.0000'}
            />
            <TextInput
              classes='!gap-0 items-center !w-36'
              labelClasses='text-yellow2 font-medium text-lg'
              inputContainerClasses='bg-[#232732] !p-0 border border-[#4f5463] font-medium'
              inputClasses='text-center text-xs xl:text-lg !w-20 xl:!w-36'
              label='Won'
              value={fundInfo.won === 0 ? fundInfo.won.toString() : fundInfo.won.toFixed(4)}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-center items-center gap-2 xl:gap-5'>
        <TextInput
          classes='!w-16 !md:w-24 xl:!w-32 bg-gray1 rounded-sm xl:rounded-lg'
          inputContainerClasses='px-1 !px-2 xl:!px-3.5 !py-1 xl:!py-2 rounded-sm xl:rounded-lg text-10 xl:text-base'
          inputClasses='w-full text-center'
          placeholder='Bet (mBTC)'
          type='number'
          value={betAmount}
          enableLabel={false}
          onChange={e => setBetAmount(e.target.value)}
          />
        <TextInput
          classes='!w-16 !md:w-24 xl:!w-32 bg-gray1 rounded-sm xl:rounded-lg'
          inputContainerClasses='px-1 !px-2 xl:!px-3.5 !py-1 xl:!py-2 rounded-sm xl:rounded-lg text-10 xl:text-base'
          inputClasses='w-full text-center'
          placeholder='Drops (Bits)'
          type='number'
          value={ball}
          enableLabel={false}
          onChange={e => handleChangeBalls(e.target.value)}
        />
        <Button
          classes='!py-1 xl:!py-2 w-20 xl:w-32 text-center font-bold shadow-lg shadow-black whitespace-nowrap text-10 xl:text-lg'
          // eslint-disable-next-line
          // @ts-ignore
          bg={ColorList[row]}
          label={`Row: ${ColorList[row]}`}
          onAction={() => handleChangeRow()}
        />
        <Button
          classes='!py-1 xl:!py-2 w-20 xl:w-28 text-center font-bold shadow-lg shadow-black text-10 xl:text-lg'
          label='Drop!'
          onAction={() => handleDrop()}
        />
      </div>
      <Modal
        classes='pb-4'
        openModal={warnModal}
        title='Warning'
        closeModal={() => setWarnModal(false)}
      >
        <Text
          classes='!text-dark1'
          type='lg-dark'
          label="Your balance is not enough. Please deposit."
        />
        <div className='flex flex-row justify-end gap-4'>
          <Button
            label='Close'
            classes='text-center w-20 !py-1'
            onAction={() => setWarnModal(false)}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Plinko;
