import React, { FC } from 'react';

import Text from '../Text';
import TextInput from '../TextInput';
import { VerifyInfo } from '../../helper/types';
import Button from '../Button';
import Select from '../Select';
import { ColorList, VerifyDesc } from '../../helper/constants';

interface BetVerifyProps {
  verifyInfo: VerifyInfo;
  type: string;
  // eslint-disable-next-line
  proofInfo: any;
  onChange: (field: string, value: string) => void;
  onSubmit: () => void;
}

const BetVerify: FC<BetVerifyProps> = props => {
  const {
    type,
    verifyInfo,
    proofInfo,
    onChange,
    onSubmit,
  } = props;

  return (
    <div className='flex flex-col py-2 md:p-10 gap-5'>
      <div className='flex flex-col'>
        <div className='flex flex-row gap-1'>
          <Text
            type='white'
            label={`${VerifyDesc.common[0]}`}
          />
          <Text
            label={`${VerifyDesc.common[1]}`}
          />
        </div>
        {type === 'dice' &&
          <Text
            type='sm-white'
            label={`${VerifyDesc.dice}`}
          />
        }
        {type === 'bitdrop' &&
          <Text
            type='white'
            label={`${VerifyDesc.bitdrop}`}
          />
        }
        {type === 'roulette' &&
          <Text
            type='white'
            label={`${VerifyDesc.roulette}`}
          />
        }
      </div>
      <div className='flex flex-col gap-4'>
        <TextInput
          label='Server Seed:'
          value={verifyInfo.serverSeed}
          onChange={e => onChange('serverSeed', e.target.value)}
        />
        <TextInput
          label='Player Seed | Nonce'
          value={verifyInfo.playerSeed}
          onChange={e => onChange('playerSeed', e.target.value)}
        />
        <div className='grid grid-cols-1 xl:grid-cols-3 gap-4 xl:gap-8'>
          {type !== 'roulette' &&
            <TextInput
              label='Bet:'
              value={verifyInfo.betAmount?.toString()}
              onChange={e => onChange('betAmount', e.target.value)}
            />
          }
          {type === 'dice' &&
            <>
              <TextInput
                label='Roll:'
                value={verifyInfo.roll?.toString()}
                onChange={e => onChange('roll', e.target.value)}
              />
              <Select
                label='Over:'
                value={verifyInfo?.over}
                optionList={[
                  { label: 'Under', value: 'false' },
                  { label: 'Over', value: 'true' }
                ]}
                onChange={e => onChange('over', e.target.value)}
              />
            </>
          }
          {type === 'bitdrop' &&
            <>
              <TextInput
                label='Balls:'
                value={verifyInfo.ball?.toString()}
                onChange={e => onChange('ball', e.target.value)}
              />
              <Select
                label='Row:'
                value={verifyInfo.row}
                optionList={ColorList.map(item => ({ value: item, label: item }))}
                onChange={e => onChange('row', e.target.value)}
              />
            </>
          }
        </div>
      </div>
      <div className='flex'>
        <Button
          label='Submit'
          classes='font-medium'
          onAction={() => onSubmit()}
        />
      </div>

      {proofInfo && <>
        <Text type='xl-white' label='Proof' />
        {type === 'dice' &&
          <div className='flex flex-col'>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Game Result:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4'>
                <Text label={proofInfo?.result} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Server Seed Hash:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4'>
                <Text label={proofInfo?.serverSeedHash} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Bet Roll:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                <Text label={proofInfo?.bet} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Won Amount:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                <Text label={proofInfo?.wonAmount} type='white' classes='break-all' />
              </div>
            </div>
          </div>
        }
        { type === 'bitdrop' &&
          <div className='flex flex-col'>
            <div className='flex flex-col w-full border-b border-gray2'>
              <div className='flex flex-row w-full'>
                <div className='w-1/5 md:w-1/4 flex justify-end p-4 pb-0'>
                  <Text label='Game Result:' classes='text-right' type='dark' />
                </div>
                <div className='w-4/5 md:w-3/4 pt-4'>
                  <Text label={proofInfo?.result} type='white' classes='break-all' />
                </div>
              </div>
              <div className='flex flex-row w-full'>
                <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                  <Text label='Server Seed Hash:' classes='text-right' type='dark' />
                </div>
                <div className='w-4/5 md:w-3/4 py-4'>
                  <Text label={proofInfo?.serverSeedHash} type='white' classes='break-all' />
                </div>
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Path:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                {proofInfo?.paths.map((item: string, index: number) => (
                  <Text key={index} label={item} type='white' />
                ))}
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Won Amount:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                {proofInfo?.wonAmountList.map((item: number, index: number) => (
                  <Text key={index} label={item.toString()} type='white' />
                ))}
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Won:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                {proofInfo?.wonList.map((item: number, index: number) => (
                  <Text key={index} label={item.toString()} type='white' />
                ))}
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Total Won Amount:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4'>
                <Text label={proofInfo?.wonAmount} type='white' />
              </div>
            </div>
          </div>
        }
        {type === 'roulette' &&
          <div className='flex flex-col'>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Game Result:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4'>
                <Text label={proofInfo?.result} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Server Seed Hash:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4'>
                <Text label={proofInfo?.serverSeedHash} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Roll:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                <Text label={proofInfo?.roll} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Bet Amount:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                <Text label={proofInfo?.betAmount} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Won Amount:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                <Text label={proofInfo?.wonAmount} type='white' classes='break-all' />
              </div>
            </div>
            <div className='flex flex-row w-full border-b border-gray2'>
              <div className='w-1/5 md:w-1/4 flex justify-end p-4'>
                <Text label='Won Rate:' classes='text-right' type='dark' />
              </div>
              <div className='w-4/5 md:w-3/4 py-4 flex flex-col'>
                <Text label={(proofInfo?.wonAmount / proofInfo?.betAmount).toFixed(2)} type='white' classes='break-all' />
              </div>
            </div>
          </div>
        }
      </>}
    </div>
  )
}

export default BetVerify;
