import React, { FC } from 'react';

interface WhiteBallProps {
  classes?: string;
  // eslint-disable-next-line
  style?: any;
}

const WhiteBall: FC<WhiteBallProps> = (props) => {
  const { classes, style } = props;

  return (
    <div
      className={`w-1 h-1 md:w-2 md:h-2 rounded-full mx-1.5 md:mx-3 bg-white ${classes}`}
      style={style}
    ></div>
  )
}

export default WhiteBall;
