import React, { FC } from 'react';

import ballImg from '../../../assets/icons/Roulette_Ball.png';

interface RouletteBallProps {
  classes?: string;
  // eslint-disable-next-line
  style?: any;
}

const RouletteBall: FC<RouletteBallProps> = (props) => {
  const { classes, style } = props;

  return (
    <div
      className={`w-3 h-3 md:w-5 md:h-5 rounded-full mx-3 md:mx-0.5 bg-transparent ${classes}`}
      style={style}
    >
      <img src={ballImg} alt='ball' />
    </div>
  )
}

export default RouletteBall;
