import React, { FC } from 'react';

import nopathImg from '../../assets/images/NoPath.png';
import Text from '../../components/Text';
import BetDataTable from '../../components/BetDataTable';
import CommonLayout from '../../layouts/CommonLayout';

const ResultCard = ({ title, content }: {title: string, content: string}) => (
  <div className='flex flex-col w-36 xl:w-56 h-max text-center py-4 xl:py-8 bg-white rounded-lg gap-2'>
    <Text classes='!text-sm xl:!text-22 !text-yellow2 font-bold' label={title} />
    <Text classes='!text-sm xl:!text-22 !text-yellow2 font-normal' label={content} />
  </div>
)

const Winners: FC = () => {
  return (
    <CommonLayout
      disableSidebar
      enableFooter
      classes='flex flex-col w-full max-w-[800px] !px-0 mx-auto mt-8 xl:mt-20 gap-10'
    >
      <div className='flex flex-col xl:flex-row'>
        <img src={nopathImg} className='w-[200px] xl:w-[310px] m-auto mb-4 xl:mb-0' />
        <div className='flex flex-row justify-center gap-2 xl:gap-8'>
          <ResultCard title='Games Played' content='120 Games' />
          <ResultCard title='Larget BTC winners' content='791.704 BTC' />
        </div>
      </div>
      <BetDataTable type='winners' defaultValue='Biggest' />
    </CommonLayout>
  )
}

export default Winners;
