import React, { FC } from 'react';

import { BetListItem } from '../../helper/types';

interface BetListTableProps {
  type?: string,
  data: BetListItem[],
  username?: string;
}

const BetListTable: FC<BetListTableProps> = props => {
  const { data, username = '', type = 'bitdrop' } = props;

  return (
    <div className='overflow-auto'>
      <table className='data-table w-full text-center'>
        <thead className='border-b border-gray2'>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>Game ID</th>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>User name</th>
          {type === 'dice' ?
            <th className='text-sm font-normal text-[#999] px-3 py-4'>Bet / Roll</th>
            :
            <>
             {type === 'roulette' ?
                <th className='text-sm font-normal text-[#999] px-3 py-4'>Roll</th>
                :
                <>
                  <th className='text-sm font-normal text-[#999] px-3 py-4'>Bet</th>
                  <th className='text-sm font-normal text-[#999] px-3 py-4'>Roll</th>
                </>
             }
            </>
          }
          <th className='text-sm font-normal text-[#999] px-3 py-4'>Total Bet</th>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>Payout</th>
          <th className='text-sm font-normal text-[#999] px-3 py-4'>Profit</th>
        </thead>
        <tbody>
          {data && data.length > 0 && data.slice(0,10).map((item, index) => (
            <tr key={index} className='even:bg-dark1'>
              {type === 'bitdrop' && <>
                <td className='text-white text-sm px-3 py-4'>{item?.id}</td>
                <td className='text-white text-sm px-3 py-4'>{username || item?.username}</td>
                <td className='text-white text-sm px-3 py-4'>{item?.betAmount}</td>
                <td className='text-white text-sm px-3 py-4'>{item?.ball}</td>
                <td className='text-white text-sm px-3 py-4'>{(item?.betAmount * item?.ball).toFixed(8)}</td>
                <td className='text-white text-sm px-3 py-4'>{item?.wonAmount.toFixed(8)}</td>
                <td className='text-white text-sm px-3 py-4'>{(item?.wonAmount - item?.betAmount * item?.ball).toFixed(8)}</td>
              </> }
              {type === 'dice' && <>
                <td className='text-white text-sm px-3 py-4'>{item?.id}</td>
                <td className='text-white text-sm px-3 py-4'>{username || item?.username}</td>
                <td className='text-white text-sm px-3 py-4'>
                  {item?.bet}
                  {item.over ? ' >= ' : ' < '}
                  {item?.roll}
                </td>
                <td className='text-white text-sm px-3 py-4'>{item?.betAmount}</td>
                <td className='text-white text-sm px-3 py-4'>{item?.wonAmount.toFixed(8)}</td>
                <td className='text-white text-sm px-3 py-4'>{(item?.wonAmount - item?.betAmount).toFixed(8)}</td>
              </> }
              {type === 'roulette' && <>
                <td className='text-white text-sm px-3 py-4'>{item?.id}</td>
                <td className='text-white text-sm px-3 py-4'>{username || item?.username}</td>
                <td className='text-white text-sm px-3 py-4'>
                  {item?.roll}
                </td>
                <td className='text-white text-sm px-3 py-4'>{item?.betAmount}</td>
                <td className='text-white text-sm px-3 py-4'>{item?.wonAmount.toFixed(8)}</td>
                <td className='text-white text-sm px-3 py-4'>{(item?.wonAmount - item?.betAmount).toFixed(8)}</td>
              </> }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default BetListTable;
