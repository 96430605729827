import React, { FC, useState, useRef } from 'react';

import Text from '../Text';

interface DropdownProps {
  // eslint-disable-next-line
  icon?: any;
  // eslint-disable-next-line
  subicon?: any;
  label: string;
  classes?: string;
  selectClasses?: string;
  dropClasses?: string;
  align?: 'left' | 'right';
  // eslint-disable-next-line
  children?: any;
}

const Dropdown: FC<DropdownProps> = props => {
  const {
    icon = null,
    subicon = null,
    label,
    classes = '',
    selectClasses = '',
    dropClasses = '',
    align = 'right',
    children = null
  } = props;
  const ref = useRef(null);

  document.addEventListener("mousedown", (event) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  });

  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div ref={ref} className={`relative ${classes}`} onClick={() => setVisible(v => !v)}>
      <div className={`flex flex-row items-center gap-2 ${selectClasses}`}>
        {icon}
        <Text type='white' label={label} />
        {subicon}
      </div>
      {visible &&
        <div
          className={`
            flex flex-col gap-5 absolute z-10 px-7 min-w-[200px] py-5 top-12 bg-[#2C3348] whitespace-nowrap
            ${align === 'left' ? 'left-0' : 'right-0'} ${dropClasses}
          `}
        >
          {children}
        </div>
      }
    </div>
  )
}

export default Dropdown;
