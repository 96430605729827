import React, { FC } from 'react';
import { IoMdClose } from 'react-icons/io'
import Text from './Text';

interface ModalProps {
  openModal: boolean;
  classes?: string;
  title?: string;
  // eslint-disable-next-line
  children: any;
  closeModal: () => void;
}

const Modal: FC<ModalProps> = props => {
  const {
    openModal,
    classes = '',
    children,
    title = '',
    closeModal,
  } = props;

  return (
    <div className={`${openModal ? 'flex' : 'hidden'} fixed top-0 bottom-0 left-0 right-0 z-20`}>
      <div  
        className='fixed top-0 bottom-0 left-0 right-0 bg-black opacity-80 z-10'
        onClick={closeModal}
      ></div>
      <div className='flex flex-col mt-14 md:m-auto  w-full max-w-[600px] h-max z-50 px-5'>
        <div className='w-full bg-pink1 relative h-14 flex items-center justify-between px-5'>
          <Text type='xl-white' label={title} classes='font-bold text-center' />
          <IoMdClose className='text-white font-bold text-2xl cursor-pointer' onClick={closeModal} />
        </div>
        <div className={`flex flex-col ${classes} gap-4 bg-white p-5`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;
