import {put, takeLeading } from 'redux-saga/effects'
import { AppActions } from '../actions'

// eslint-disable-next-line
function* init (action: any) {
    yield  put(AppActions.appInitAction())
}
function* performInit() {
    yield takeLeading(AppActions.appInitAction, init)
}

export const appSagas = [
    performInit
]
