import React from 'react';
import Text from '../components/Text';
import CommonLayout from '../layouts/CommonLayout';

const EmptyPage = () => {
  return (
    <CommonLayout>
      <div className='w-full h-full p-12'>
        <Text type='title' label='Empty Page' />
      </div>
    </CommonLayout>
  )
}

export default EmptyPage;
