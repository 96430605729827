import { createReducer } from '@reduxjs/toolkit'
import { AppActions } from '../actions'

interface IAppState  {
    isLoading: boolean,
    successMsg: null,
    failedMsg: null
}

const initialState: IAppState = {
    isLoading: false,
    successMsg: null,
    failedMsg: null
}

const appReducer = createReducer(initialState, {
    [AppActions.appInitAction.toString()]: (state: IAppState) => {
        state.isLoading = false
        state.successMsg = null
        state.failedMsg = null
    },
    [AppActions.loadingAction.toString()]: (state: IAppState) => {
        state.isLoading = true
    },
    [AppActions.loadingEndAction.toString()]: (state: IAppState) => {
        state.isLoading = false
    },
    [AppActions.appSuccessAction.toString()]: (state: IAppState, action) => {
        state.isLoading = false
        state.successMsg = action.payload || 'Success'
    },
    [AppActions.appFailAction.toString()]: (state: IAppState, action) => {
        state.isLoading = false
        state.failedMsg = action.payload || 'Failed'
    },
    [AppActions.appInitMsgAction.toString()]: (state: IAppState) => {
        state.successMsg = null
        state.failedMsg = null
    }
})

export default appReducer
