import React, { FC, useEffect, useState } from 'react';

import Text from '../Text';
import BetListTable from './BetListTable';
import BetUsersTable from './BetUsersTable';
import BetRules from './BetRules';
import BetProof from './BetProof';
import BetVerify from './BetVerify';
import useAppData from '../../hooks/useAppData';
import useAuth from '../../hooks/useAuth';
import { VerifyInfo } from '../../helper/types';
import plinkService from '../../services/plinkService';
import diceService from '../../services/diceService';
import rouletteService from '../../services/rouletteService';

interface BetDataTableProps {
  type: 'bitdrop' | 'dashboard' | 'winners' | 'dice' | 'roulette';
  defaultValue?: string;
}

const BetDataTable: FC<BetDataTableProps> = props => {
  const { type, defaultValue='Bets' } = props;
  const { user } = useAuth();
  const { betList, userBetList, bigRollers, winners, gameResult } = useAppData();

  const [tab, setTab] = useState<string>(defaultValue);
  const [tabList, setTabList] = useState<string[]>([]);
  const [verifyInfo, setVerifyInfo] = useState<VerifyInfo>({ over: 'false', row: 'Red' } as VerifyInfo);
  // eslint-disable-next-line
  const [proofInfo, setProofInfo] = useState<any>(null);

  useEffect(() => {
    if (type) {
      const tabs = initTabs();
      setTabList(tabs);
    }
  }, [type])

  useEffect(() => {
    console.log('winner data: ', winners);
  }, [winners])
  
  const initTabs = () => {
    switch (type) {
      case 'bitdrop':
        return ['Bets', 'Your Bets', 'Big Rollers', 'Biggest Winners', 'Rules', 'Proof', 'Verify']
      case 'dice':
        return ['Bets', 'Your Bets', 'Biggest Winners', 'Proof', 'Verify']
      case 'roulette':
        return ['Bets', 'Your Bets', 'Biggest Winners', 'Proof', 'Verify']
      case 'dashboard':
        return ['Bets', 'Your Bets', 'Big Rollers', 'Biggest Winners']
      case 'winners':
        return ['Biggest', 'Most Recent']
      default:
        return []
    }
  }

  const handleChangeVerifyInfo = (field: string, value: string) => {
    if (field === 'ball' && (parseInt(value) < 1 || parseInt(value) > 10)) {
      return;
    }
    setVerifyInfo(v => ({ ...v, [field]: value }));
  }

  const handleSubmit = async () => {
    if (type === 'dice') {
      // eslint-disable-next-line
      // @ts-ignore
      const result = await diceService.verify({ ...verifyInfo, over: verifyInfo.over === 'false' ? false : true });
      if (result.$success) {
        setProofInfo(result.result);
        console.log('result: ', result);
      }
    } else if (type === 'bitdrop') {
      const result = await plinkService.verify({ ...verifyInfo });
      if (result.$success) {
        setProofInfo(result.result);
        console.log('result: ', result);
      }
    } else if (type === 'roulette') {
      const result = await rouletteService.verify({ ...verifyInfo });
      if (result.$success) {
        setProofInfo(result.result);
        console.log('result: ', result);
      }
    }
  }

  return (
    <div className='flex flex-col bg-gray1'>
      <div className='flex flex-row overflow-auto px-5 gap-4 xl:gap-6 border-b border-gray2'>
        {tabList.map((item, index) => (
          <div
            key={index}
            className={`py-4 cursor-pointer ${tab === item && 'border-b border-yellow1'}`}
            onClick={() => setTab(item)}
          >
            <Text label={item} type={tab === item ? 'sm-yellow' : 'sm-white'} classes='whitespace-nowrap' />
          </div>
        ))}
      </div>
      <div className='px-5 pb-5'>
        {tab === 'Bets' && <BetListTable type={type} data={betList} />}
        {tab === 'Your Bets' && <BetListTable type={type} data={userBetList} username={user.username} />}
        {tab === 'Big Rollers' && <BetListTable data={bigRollers} />}
        {tab === 'Biggest Winners' && <BetUsersTable data={winners} />}
        {tab === 'Rules' && <BetRules type={type} />}
        {tab === 'Proof' && <BetProof type={type} data={{...gameResult}} />}
        {tab === 'Verify' &&
          <BetVerify
            type={type}
            verifyInfo={verifyInfo}
            proofInfo={proofInfo}
            onChange={handleChangeVerifyInfo}
            onSubmit={handleSubmit}
          />}
        {tab === 'Biggest' && <BetUsersTable data={winners} />}
        {tab === 'Most Recent' && <BetListTable data={betList} />}
      </div>
    </div>
  )
}

export default BetDataTable;
