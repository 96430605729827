import React, { FC } from "react";
import CommonLayout from "../../layouts/CommonLayout";
import BetDataTable from "../../components/BetDataTable";
import Plinko from "../../components/Games/Plinko";

const BitDropPage: FC = () => {
  return (
    <CommonLayout classes="gap-8">
      <Plinko />
      <BetDataTable type="bitdrop" />
    </CommonLayout>
  )
}

export default BitDropPage
