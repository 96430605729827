import React from 'react';
import './App.css';
import { AppRoutes } from './routes';
import { Provider } from 'react-redux';
import { store } from './redux/Store';
import { AppDataProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <Provider store={store}>
      <AppDataProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </AppDataProvider>
    </Provider>
  );
}

export default App;
