import React, { FC, useEffect } from 'react';

import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import Header from './Header';
import './index.css';
import Footer from './Footer';
import useAuth from '../hooks/useAuth';
import useAppData from '../hooks/useAppData';
import { useLocation } from 'react-router-dom';
import { IGameResult } from '../context/AppContext';

interface CommonLayoutProps {
  classes?: string;
  disableSidebar?: boolean;
  enableFooter?: boolean;
  // eslint-disable-next-line
  children: any;
}

const CommonLayout: FC<CommonLayoutProps> = (props) => {
  const { classes='', enableFooter = false, disableSidebar = false, children } = props;
  const { loginFree, registerFree, isAuthenticated } = useAuth();
  const { updateBalance, updateCurBalance, updateGameResult, updateNewSeed } = useAppData();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      const username = localStorage.getItem('username');
      if (username) {
        loginFree(username)
          .then(res => {
            if (res) {
              updateBalance(res.balance);
              updateCurBalance(res.balance['btc']);
              updateNewSeed(res.seedInfo);
            }
          })
      } else {
        registerFree()
          .then(res => {
            if (res) {
              updateBalance(res.balance);
              updateCurBalance(res.balance['btc']);
            }
          })
      }
    }
  }, [])

  useEffect(() => {
    updateGameResult({} as IGameResult)
  }, [location])

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     plinkService.getList()
  //       .then(res => {
  //         if (res.$success) {
  //           updateBetList(res.data.betList);
  //           updateUserBetList(res.data.userBetList);
  //           updateBigRollers(res.data.bigRollers);
  //           updateWinners(res.data.winnerList);
  //         }
  //       })
  //   }
  // }, [isAuthenticated])

  return (
    <div className='flex flex-col h-[100vh]'>
      <Header classes='z-20' />
      <div className='flex flex-row flex-1 relative main-body overflow-auto z-10'>
        {!disableSidebar && <LeftSidebar classes='md:w-[12.5%] md:min-w-[200px]' />}
        {disableSidebar &&<LeftSidebar classes='md:!hidden' />}
        <div className={`flex flex-col px-8 py-4 xl:py-8 overflow-auto ${disableSidebar && 'pb-16'} flex-1 relative ${classes}`}>
          {children}
        </div>
        {!disableSidebar && <RightSidebar classes='z-30' disableSidebar={disableSidebar} />}
        {enableFooter && <Footer />}
      </div>
    </div>
  )
}

export default CommonLayout;
