import React, { FC } from 'react';
import Text from '../components/Text';

const Footer: FC = () => {
  return (
    <div className='absolute bottom-0 w-full bg-pink1 flex justify-center py-7'>
      <Text
        classes='font-light'
        type='white'
        label='Website & Contents Copyright 2013-2020'
      />
    </div>
  )
}

export default Footer;
