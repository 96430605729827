import React, { FC, useState } from 'react';

import CommonLayout from '../../layouts/CommonLayout';
import Card from '../../components/Card';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

import walletIcon from '../../assets/icons/account_balance_wallet-24px (1).svg'
import depositIcon from '../../assets/icons/wallet (1).svg'
import withdrawIcon from '../../assets/icons/income (1).svg'
import historyIcon from '../../assets/icons/money (5).svg'
import accountIcon from '../../assets/icons/account_circle-24px.svg'
import userIcon from '../../assets/icons/person-24px (2).svg'
import lockIcon from '../../assets/icons/lock-24px.svg'
import checkIcon from '../../assets/icons/check_circle-24px.svg'
import peopleIcon from '../../assets/icons/people_alt-24px.svg'
import languageIcon from '../../assets/icons/language-24px.svg'

interface AccountInfo {
  username: string;
  password: string;
  confirmPassword: string;
}

// eslint-disable-next-line
const FundItem = ({ icon, label, onAction=()=>{} }: {icon: any, label: string, onAction:()=>void }) => (
  <div
    className='flex flex-row justify-center py-2.5 bg-dark4 rounded-lg w-64 gap-4'
    onClick={() => onAction()}
  >
    <img src={icon} width={22} />
    <Text type='dark' label={label} />
  </div>
)

const Setting: FC = () => {
  const [accountInfo, setAccountInfo] = useState<AccountInfo>({} as AccountInfo);
  
  const handleFund = (type: string) => {
    console.log('handleFund: ', type);
  }

  const handleChangeAccount = (field: string, value: string) => {
    setAccountInfo(v => ({ ...v, [field]: value }))
  }

  const handleConvertAccoutn = () => {}

  return (
    <CommonLayout
      disableSidebar
      enableFooter
      classes='flex flex-col w-full max-w-[1100px] !px-0 mx-auto mt-8 gap-4'
    >
      <Card
        title='Manage Funds'
        icon={walletIcon}
        contentClasses='flex flex-col xl:flex-row gap-4 xl:gap-16 mx-auto py-9'
      >
        <FundItem icon={depositIcon} label='Deposit' onAction={() => handleFund('deposit')} />
        <FundItem icon={withdrawIcon} label='Withdraw' onAction={() => handleFund('withdraw')} />
        <FundItem icon={historyIcon} label='Funding History' onAction={() => handleFund('history')} />
      </Card>
      <Card
        title='Convert to Full Account'
        icon={accountIcon}
        contentClasses='flex flex-col gap-4 mx-auto py-9'
      >
        <TextInput
          enableLabel={false}
          inputContainerClasses='!border-none bg-dark4 rounded gap-1 py-2.5 w-[250px] xl:w-[350px]'
          inputClasses='text-sm'
          sub={<img src={userIcon} />}
          placeholder='Username'
          value={accountInfo.username}
          onChange={e => handleChangeAccount('username', e.target.value)}
        />
        <TextInput
          enableLabel={false}
          inputContainerClasses='!border-none bg-dark4 rounded gap-1 py-2.5 w-[250px] xl:w-[350px]'
          inputClasses='text-sm'
          sub={<img src={lockIcon} />}
          placeholder='Password'
          value={accountInfo.password}
          onChange={e => handleChangeAccount('password', e.target.value)}
        />
        <TextInput
          enableLabel={false}
          inputContainerClasses='!border-none bg-dark4 rounded gap-1 py-2.5 w-[250px] xl:w-[350px]'
          inputClasses='text-sm'
          sub={<img src={lockIcon} />}
          placeholder='Confirm Password'
          value={accountInfo.confirmPassword}
          onChange={e => handleChangeAccount('confirmPassword', e.target.value)}
        />
        <Button
          label='Convert to full Account'
          classes='text-sm text-center py-2.5'
          onAction={() => handleConvertAccoutn()}
        />
      </Card>
      <Card
        title='Two Factor Google Authenticator'
        icon={checkIcon}
        contentClasses='flex flex-col gap-6 mx-auto py-9'
      >
        <div className='flex flex-row justify-center gap-1'>
          <Text type='white' label='2-Factor Google Authentication is' />
          <Text classes='!text-[#dc3545]' label='disabled' />
        </div>
        <Text type='white' label='Create a full account to enable two-factor authentication.' />
      </Card>
      <Card
        title='Referrals'
        icon={peopleIcon}
        contentClasses='flex flex-col items-center gap-6 mx-auto py-9'
      >
        <Text
          type='white'
          classes='max-w-[720px] px-4'
          label='When a user signs up using your referral link below you will receive a portion of our earnings from that user. Additionally, you will earn a share of the maxi jackpot if your referral wins big!'
        />
        <Button
          label={`${window.location.host}/?ref=1000008`}
          classes='w-max'
          onAction={() => {}}
        />
      </Card>
      <Card
        title='Secret/Recovery URL'
        icon={languageIcon}
        contentClasses='flex flex-col items-center gap-6 mx-auto py-9'
      >
        <Text
          type='white'
          classes='max-w-[720px] px-4'
          label='The URL below will allow you to log in to your account on other browsers/computers without requiring a full account. Do NOT share the URL with anyone, it will provide anyone full access to your account until you convert to a full one!'
        />
        <Button
          label={`${window.location.host}/?s=4rmmvOkOyRf6bDXt3tccqg`}
          classes='w-max'
          onAction={() => {}}
        />
      </Card>
    </CommonLayout>
  )
}

export default Setting;
