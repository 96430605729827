import React, { FC } from 'react';

interface TextProps {
  label: string;
  type?: 'normal' | 'dark' | 'white' | 'xs-white' | 'xs-yellow' | 'xs-dark' | 'sm-white' | 'sm-yellow' | 'sm-dark' | 'lg-white' | 'xl-white' | 'lg-dark' | '2xl-yellow' | 'title' | 'title-yellow';
  classes?: string;
}

const Text: FC<TextProps> = props => {
  const { label, type = '', classes = '' } = props;

  const textStyle = () => {
    switch (type) {
      case 'title':
        return 'text-4xl text-white'
      case 'title-yellow':
        return 'text-2xl md:text-3xl xl:text-4xl text-yellow1'
      case 'xl-white':
        return 'text-white text-xl'
      case '2xl-yellow':
        return 'text-yellow1 text-2xl'
      case 'lg-white':
        return 'text-white text-lg'
      case 'lg-dark':
        return 'text-dark2 text-lg'
      case 'sm-white':
        return 'text-white text-sm'
      case 'sm-yellow':
        return 'text-yellow1 text-sm'
      case 'sm-dark':
        return 'text-dark2 text-sm'
      case 'xs-white':
        return 'text-white text-xs'
      case 'xs-dark':
        return 'text-dark2 text-xs'
      case 'xs-yellow':
        return 'text-yellow1 text-xs'
      case 'white':
        return 'text-white text-sm md:text-base'
      case 'dark':
        return 'text-dark2 text-sm md:text-base'
      default:
        return 'text-yellow1 text-base'
    }
  }

  return (
    <span className={`${textStyle()} ${classes}`}>
      {label}
    </span>
  )
}

export default Text;
