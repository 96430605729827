import { createAction} from '@reduxjs/toolkit';

export const appInitAction = createAction('app/init')

export const loadingAction = createAction('app/loading')
export const loadingEndAction = createAction('app/loadingend')

// eslint-disable-next-line
export const appSuccessAction = createAction<any>('app/success');
// eslint-disable-next-line
export const appFailAction = createAction<any>('app/fail');
export const appInitMsgAction = createAction('app/init/msg');
