import React, { FC, useState } from 'react';

import Text from '../components/Text';
import sendIcon from '../assets/icons/send-icon.svg'
import moreIcon from '../assets/icons/more-icon.svg'
import chatIcon from '../assets/icons/chat-icon.svg';

interface RightSidebarProps {
  visible?: boolean;
  disableSidebar: boolean;
  classes?: string;
}

const RightSidebar: FC<RightSidebarProps> = ({ disableSidebar, classes }) => {
  const [msg, setMsg] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className={`flex flex-col absolute right-0 xl:relative h-full bg-gray1 duration-500 ${visible ? 'w-[300px] xl:w-1/5 ' : 'w-0'} ${classes}`}>
      <div className={`flex flex-row bg-gray2 py-3 duration-500 ease-out ${visible ? 'px-4' : 'px-0'}`}>
        {visible && <Text type='sm-white' label='CHAT' />}
      </div>
      <div className='flex flex-col flex-1'>
        
      </div>
      {visible &&
        <div className='flex flex-col px-4 py-6 border-t border-gray-600 gap-4'>
          <div className={`flex flex-row items-start border border-gray-600 rounded ${visible ? 'p-4' : 'w-0'} bg-gray-800 gap-2`}>
            <Text type='sm-dark' label='CHAT:' />
            <textarea
              className='flex-1 bg-transparent text-sm outline-none border-none text-white'
              placeholder='Type here :)'
              cols={3}
              value={msg}
              onChange={e => setMsg(e.target.value)}
            />
            <img src={sendIcon} />
          </div>
          <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row items-center gap-1'>
              <Text
                type='sm-white'
                classes='before:inline-block before:w-1.5 before:h-1.5 before:bg-green-300 before:rounded-full before:-mt-2 before:mr-1.5'
                label='USERS ONLINE:'
              />
              <Text type='sm-white' classes='!text-green-300' label='1' />
            </div>
            <div className='flex flex-row items-center gap-2'>
              <img src={moreIcon} />
              <Text type='sm-dark' label='CHAT RULES' />
            </div>
          </div>
        </div>
      }
      {!disableSidebar &&
        <div
          className='cursor-pointer bg-yellow2 rounded-full w-max p-3 absolute bottom-5 -left-20'
          onClick={() => setVisible(v => !v)}
        >
          <img src={chatIcon} />
        </div>
      }
    </div>
  )
}

export default RightSidebar;
