import { APIS } from "../helper/constants";
import { VerifyInfo } from "../helper/types";
import AxiosInstance from "./apiClient"

const drop = async (
  data: { betAmount: number, ball: number, row: string, playerSeed: string; }
) => {
  const res = await AxiosInstance.post(APIS.PLINKO, data);
  return res.data;
}

const getList = async () => {
  const res = await AxiosInstance.get(APIS.PLINKO);
  return res.data;
}

const verify = async ( data: VerifyInfo) => {
  const res = await AxiosInstance.post(APIS.PLINKO_VERIFY, data);
  return res.data;
}

export default {
  drop,
  getList,
  verify,
}
