import React, { FC, useEffect, useState } from 'react';

import './index.css';
import { NumbersArea } from '../../../helper/constants';
import { ChipItemType, RouletteBetItem } from '../../../helper/types';
import ChipItem from './ChipItem';
import { getChipItem } from '../../../helper/functions';
import NumItem from './NumItem';

interface NumsProps {
  roll: number;
  betList: RouletteBetItem[];
  classes?: string;
  onBetAction: (key: string, increase: number) => void;
}

const NumArray = [3, 2, 1, 6, 5, 4, 9, 8, 7, 12, 11, 10, 15, 14, 13, 18, 17, 16, 21, 20, 19, 24, 23, 22, 27, 26, 25, 30, 29, 28, 33, 32, 31, 36, 35, 34]
const NumsArea: FC<NumsProps> = (props) => {
  const {
    roll,
    betList,
    classes = '',
    onBetAction,
  } = props;

  const [activeList, setActiveList] = useState<number[]>([]);

  const [emptyItem, setEmptyItem] = useState<ChipItemType | null>(null);
  // const [emptyOneT, setEmptyOneT] = useState<ChipItemType | null>(null);
  const [emptyOne, setEmptyOne] = useState<ChipItemType | null>(null);
  const [emptyOneTwo, setEmptyOneTwo] = useState<ChipItemType | null>(null);
  const [emptyTwo, setEmptyTwo] = useState<ChipItemType | null>(null);
  const [emptyTwoThree, setEmptyTwoThree] = useState<ChipItemType | null>(null);
  const [emptyThree, setEmptyThree] = useState<ChipItemType | null>(null);
  const [emptyThreeB, setEmptyThreeB] = useState<ChipItemType | null>(null);

  const [first12Item, setFirst12Item] = useState<ChipItemType | null>(null);
  const [second12Item, setSecond12Item] = useState<ChipItemType | null>(null);
  const [third12Item, setThird12Item] = useState<ChipItemType | null>(null);
  const [first18Item, setFirst18Item] = useState<ChipItemType | null>(null);
  const [evenItem, setEvenItem] = useState<ChipItemType | null>(null);
  const [redItem, setRedItem] = useState<ChipItemType | null>(null);
  const [darkItem, setDarkItem] = useState<ChipItemType | null>(null);
  const [oddItem, setOddItem] = useState<ChipItemType | null>(null);
  const [second18Item, setSecond18Item] = useState<ChipItemType | null>(null);
  const [firstLineItem, setFirstLineItem] = useState<ChipItemType | null>(null);
  const [secondLineItem, setSecondLineItem] = useState<ChipItemType | null>(null);
  const [thirdLineItem, setThirdLineItem] = useState<ChipItemType | null>(null);

  useEffect(() => {
    console.log('betList: ', betList)
    let betItem = betList.find(item => item.area === '0');
    if (betItem) {
      setEmptyItem(getChipItem(betItem.betAmount));
    } else {
      setEmptyItem(null);
    }

    // betItem = betList.find(item => item.area === 't-2');
    // if (betItem) {
    //   setEmptyOneT(getChipItem(betItem.betAmount));
    // } else {
    //   setEmptyOneT(null);
    // }

    betItem = betList.find(item => item.area === 'h-1.5');
    if (betItem) {
      setEmptyOne(getChipItem(betItem.betAmount));
    } else {
      setEmptyOne(null);
    }

    betItem = betList.find(item => item.area === 'm-1');
    if (betItem) {
      setEmptyOneTwo(getChipItem(betItem.betAmount));
    } else {
      setEmptyOneTwo(null);
    }

    betItem = betList.find(item => item.area === 'h-0.5');
    if (betItem) {
      setEmptyTwo(getChipItem(betItem.betAmount));
    } else {
      setEmptyTwo(null);
    }

    betItem = betList.find(item => item.area === 'm-0');
    if (betItem) {
      setEmptyTwoThree(getChipItem(betItem.betAmount));
    } else {
      setEmptyTwoThree(null);
    }

    betItem = betList.find(item => item.area === 'h--0.5');
    if (betItem) {
      setEmptyThree(getChipItem(betItem.betAmount));
    } else {
      setEmptyThree(null);
    }

    betItem = betList.find(item => item.area === 'm--1');
    if (betItem) {
      setEmptyThreeB(getChipItem(betItem.betAmount));
    } else {
      setEmptyThreeB(null);
    }

    betItem = betList.find(item => item.area === 'first-12');
    if (betItem) {
      setFirst12Item(getChipItem(betItem.betAmount));
    } else {
      setFirst12Item(null);
    }

    betItem = betList.find(item => item.area === 'second-12');
    if (betItem) {
      setSecond12Item(getChipItem(betItem.betAmount));
    } else {
      setSecond12Item(null);
    }

    betItem = betList.find(item => item.area === 'third-12');
    if (betItem) {
      setThird12Item(getChipItem(betItem.betAmount));
    } else {
      setThird12Item(null);
    }

    betItem = betList.find(item => item.area === 'first-18');
    if (betItem) {
      setFirst18Item(getChipItem(betItem.betAmount));
    } else {
      setFirst18Item(null);
    }

    betItem = betList.find(item => item.area === 'second-18');
    if (betItem) {
      setSecond18Item(getChipItem(betItem.betAmount));
    } else {
      setSecond18Item(null);
    }

    betItem = betList.find(item => item.area === 'even');
    if (betItem) {
      setEvenItem(getChipItem(betItem.betAmount));
    } else {
      setEvenItem(null);
    }

    betItem = betList.find(item => item.area === 'odd');
    if (betItem) {
      setOddItem(getChipItem(betItem.betAmount));
    } else {
      setOddItem(null);
    }

    betItem = betList.find(item => item.area === 'red');
    if (betItem) {
      setRedItem(getChipItem(betItem.betAmount));
    } else {
      setRedItem(null);
    }

    betItem = betList.find(item => item.area === 'dark');
    if (betItem) {
      setDarkItem(getChipItem(betItem.betAmount));
    } else {
      setDarkItem(null);
    }

    betItem = betList.find(item => item.area === 'first-line');
    if (betItem) {
      setFirstLineItem(getChipItem(betItem.betAmount));
    } else {
      setFirstLineItem(null);
    }

    betItem = betList.find(item => item.area === 'second-line');
    if (betItem) {
      setSecondLineItem(getChipItem(betItem.betAmount));
    } else {
      setSecondLineItem(null);
    }

    betItem = betList.find(item => item.area === 'third-line');
    if (betItem) {
      setThirdLineItem(getChipItem(betItem.betAmount));
    } else {
      setThirdLineItem(null);
    }
  }, [betList])

  const handleHoverAction = (status: string) => {
    const numbersArea = ['first-line', 'second-line', 'third-line', 'first-12', 'second-12', 'third-12', 'first-18', 'second-18', 'even', 'odd', 'red', 'dark']
    if (numbersArea.includes(status)) {
      // eslint-disable-next-line
      // @ts-ignore
      setActiveList(NumbersArea[status]);
    } else {
      const value = parseFloat(status.slice(2))
      if (status.includes('m')) {
        if (value > 35)
          setActiveList([value-2, value-1, value+2, value+1])
        else if (value > 2)
          setActiveList([value-2, value-1, value+2, value+1])
        else if (value === 1)
          setActiveList([0, 2, 3])
        else if (value === 0)
          setActiveList([0, 1, 2])
      } else if (status.includes('b')) {
        if (value === 35)
          setActiveList([34, 35, 36])
        else if (value >= 2) {
          if (value % 1 === 0)
            setActiveList([value-1, value, value+1, value+2, value+3, value+4])
          else
            setActiveList([value+0.5, value+1.5, value+2.5])
        } else if (value === 0.5)
          setActiveList([1, 2, 3])
        else
          setActiveList([0, 1, 2, 3])
      } else if (status.includes('v')) {
        setActiveList([value - 0.5, value + 0.5])
      } else if (status.includes('h')) {
        if (value < 3)
          setActiveList([0, value + 1.5])
        else
          setActiveList([value - 1.5 < 0 ? 0 : value - 1.5, value + 1.5])
      }
    }
  }

  const handleLeaveAction = () => {
    setActiveList([]);
  }

  // eslint-disable-next-line
  const handleBetAction = (area: string, event: any) => {
    let increase = 1;
    if (event.button === 2) increase = -1;

    onBetAction(area, increase);
  }

  return (
    <div
      className={`flex flex-row area-section sm:gap-1 lg:gap-2 text-white ${classes}`}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div
        className={`
          zero flex h-16 sm:h-[104px] lg:h-40 relative rounded
          ${activeList.includes(0) ?
            'bg-yellow2 active'
            :
            'bg-yellow1 hover:bg-yellow2'
          }
        `}
      >
        <span className='w-5 sm:w-8 lg:w-12 h-full text-white text-[10px] sm:text-sm lg:text-base flex items-center justify-center border'>
          0
        </span>
        <div
          className='absolute sm:w-6 h-full lg:w-9'
          onMouseDown={(e) => handleBetAction('0', e)}
        >
          {emptyItem &&
            <ChipItem
              {...emptyItem}
              classes='!w-[16px] !h-[16px] lg:!w-[28px] lg:!h-[28px] sm:!w-[18px] sm:!h-[18px] top-[20px] -left-0.5 sm:top-[42px] sm:left-1.5 lg:top-[66px] lg:left-2.5 sm:!border-0 sm:!m-0'
              labelClasses='!w-[10px] !h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] !text-[6px]'
            />
          }
        </div>
        <div
          className='absolute right-0 top-0 sm:w-2 sm:h-2 lg:w-3 lg:h-3'
          onMouseDown={(e) => handleBetAction('0', e)}
        >
        </div>
        <div
          className='absolute sm:-right-0.5 lg:-right-1 top-2 lg:top-3 sm:w-2.5 sm:h-4 lg:w-4 lg:h-6'
          onMouseDown={(e) => handleBetAction('h-1.5', e)}
        >
          {emptyOne &&
            <ChipItem
              {...emptyOne}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-top-[2px] sm:left-0.5 lg:-top-1 lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:-right-0.5 lg:-right-1 top-6 lg:top-9 sm:w-2.5 sm:h-5 lg:w-4 lg:h-8'
          onMouseDown={(e) => handleBetAction('m-1', e)}
        >
          {emptyOneTwo &&
            <ChipItem
              {...emptyOneTwo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-top-[0px] sm:left-00 lg:-top-[0px] lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:-right-0.5 lg:-right-1 top-[44px] lg:top-[68px] sm:w-2.5 sm:h-4 lg:w-4 lg:h-6'
          onMouseDown={(e) => handleBetAction('h-0.5', e)}
        >
          {emptyTwo &&
            <ChipItem
              {...emptyTwo}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-top-[2px] sm:left-0.5 lg:-top-1 lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:-right-0.5 lg:-right-1 top-[60px] lg:top-[92px] sm:w-2.5 sm:h-[18px] lg:w-4 lg:h-8'
          onMouseDown={(e) => handleBetAction('m-0', e)}
        >
          {emptyTwoThree &&
            <ChipItem
              {...emptyTwoThree}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:top-0 sm:left-0 lg:top-0 lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:-right-0.5 lg:-right-1 top-[78px] lg:top-[124px] sm:w-2.5 sm:h-4 lg:w-4 lg:h-6'
          onMouseDown={(e) => handleBetAction('h--0.5', e)}
        >
          {emptyThree &&
            <ChipItem
              {...emptyThree}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:-top-[0px] sm:left-0.5 lg:-top-1 lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
        <div
          className='absolute sm:-right-0.5 lg:-right-1 top-[94px] lg:top-[148px] sm:w-2.5 sm:h-[18px] lg:w-4 lg:h-8'
          onMouseDown={(e) => handleBetAction('b--1', e)}
        >
          {emptyThreeB &&
            <ChipItem
              {...emptyThreeB}
              classes='sm:!w-[18px] sm:!h-[18px] lg:!w-[28px] lg:!h-[28px] sm:top-0.5 sm:left-0 lg:top-0 lg:left-0.5 sm:!border-0 sm:!m-0'
              labelClasses='sm:!w-[10px] sm:!h-[10px] lg:!w-[18px] lg:!h-[18px] lg:!text-[10px] sm:!text-[6px]'
            />
          }
        </div>
      </div>
      <div className='flex flex-col gap-1 lg:gap-2 ml-0.5 sm:ml-0'>
        <div className='flex-1 grid grid-cols-12 grid-rows-3 grid-flow-col sm:gap-1 lg:gap-2 z-20'>
          {NumArray.map((item) => (
            <NumItem
              key={item}
              label={item}
              rollout={item === roll}
              active={activeList.includes(item)}
              betList={betList}
              onAction={handleBetAction}
              onHoverAction={handleHoverAction}
              onLeaveAction={handleLeaveAction}
            />
          ))}
        </div>
        <div className='grid grid-cols-6 gap-2 z-10'>
          <div
            className='col-span-2 relative'
            onMouseOver={() => handleHoverAction('first-12')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('first-12', e)}
          >
            <span className='border h-6 sm:h-10 lg:h-12 bg-yellow1 text-[8px] sm:text-sm lg:text-base hover:bg-yellow2 flex justify-center items-center'>
              1ST 12
            </span>
            {first12Item &&
              <ChipItem
                {...first12Item}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0 left-8 sm:top-0.5 sm:left-[56px] lg:top-1.5 lg:left-[90px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='col-span-2 relative'
            onMouseOver={() => handleHoverAction('second-12')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('second-12', e)}
          >
            <span className='border h-6 sm:h-10 lg:h-12 bg-yellow1 text-[8px] sm:text-sm lg:text-base hover:bg-yellow2 flex justify-center items-center'>
              2ND 12
            </span>
            {second12Item &&
              <ChipItem
                {...second12Item}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0 left-8 sm:top-0.5 sm:left-[56px] lg:top-1.5 lg:left-[90px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='col-span-2 relative'
            onMouseOver={() => handleHoverAction('third-12')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('third-12', e)}
          >
            <span className='border h-6 sm:h-10 lg:h-12 bg-yellow1 text-[8px] sm:text-sm lg:text-base hover:bg-yellow2 flex justify-center items-center'>
              3RD 12
            </span>
            {third12Item &&
              <ChipItem
                {...third12Item}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0 left-8 sm:top-0.5 sm:left-[56px] lg:top-1.5 lg:left-[90px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='relative'
            onMouseOver={() => handleHoverAction('first-18')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('first-18', e)}
          >
            <span className='border h-6 sm:h-10 lg:h-12 bg-yellow1 text-[8px] sm:text-sm lg:text-base hover:bg-yellow2 flex justify-center items-center'>
              1 TO 18
            </span>
            {first18Item &&
              <ChipItem
                {...first18Item}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0.5 left-2 sm:top-1 sm:left-[18px] lg:top-1.5 lg:left-[34px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='relative'
            onMouseOver={() => handleHoverAction('even')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('even', e)}
          >
            <span className='border h-6 sm:h-10 lg:h-12 bg-yellow1 text-[8px] sm:text-sm lg:text-base hover:bg-yellow2 flex justify-center items-center'>
              EVEN
            </span>
            {evenItem &&
              <ChipItem
                {...evenItem}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0.5 left-2 sm:top-1 sm:left-[18px] lg:top-1.5 lg:left-[34px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='relative h-6 sm:h-10 lg:h-12 p-2 bg-yellow1 text-[8px] sm:text-sm lg:text-base num-red'
            onMouseOver={() => handleHoverAction('red')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('red', e)}
          >
            <span className='border w-5 h-5 sm:w-8 sm:h-8 lg:w-10 lg:h-10 mx-0.5 -my-1 sm:mx-3 sm:my-0.5 lg:mx-6 lg:-my-1 flex justify-center items-center bg-red-800 hover:bg-red-500'>
            </span>
            {redItem &&
              <ChipItem
                {...redItem}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0.5 left-2 sm:top-1 sm:left-[18px] lg:top-1.5 lg:left-[34px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='relative h-6 sm:h-10 lg:h-12 p-2 bg-yellow1 text-[8px] sm:text-sm lg:text-base num-dark'
            onMouseOver={() => handleHoverAction('dark')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('dark', e)}
          >
            <span className='border w-5 h-5 sm:w-8 sm:h-8 lg:w-10 lg:h-10 mx-0.5 -my-1 sm:mx-3 sm:-my-0.5 lg:mx-6 lg:-my-1 flex justify-center items-center bg-gray-800 hover:bg-gray-500'>
            </span>
            {darkItem &&
              <ChipItem
                {...darkItem}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0.5 left-2 sm:top-1 sm:left-[18px] lg:top-1.5 lg:left-[34px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='relative'
            onMouseOver={() => handleHoverAction('odd')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('odd', e)}
          >
            <span className='border h-6 sm:h-10 lg:h-12 bg-yellow1 text-[8px] sm:text-sm lg:text-base hover:bg-yellow2 flex justify-center items-center'>
              ODD
            </span>
            {oddItem &&
              <ChipItem
                {...oddItem}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0.5 left-2 sm:top-1 sm:left-[18px] lg:top-1.5 lg:left-[34px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
          <div
            className='relative'
            onMouseOver={() => handleHoverAction('second-18')}
            onMouseLeave={() => handleLeaveAction()}
            onMouseDown={(e) => handleBetAction('second-18', e)}
          >
            <span className='border h-6 sm:h-10 lg:h-12 bg-yellow1 text-[8px] sm:text-sm lg:text-base hover:bg-yellow2 flex justify-center items-center'>
              19 TO 36
            </span>
            {second18Item &&
              <ChipItem
                {...second18Item}
                classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute top-0.5 left-2 sm:top-1 sm:left-[18px] lg:top-1.5 lg:left-[34px]'
                labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
              />
            }
          </div>
        </div>
      </div>
      <div className='grid grid-rows-3 h-16 sm:h-[104px] lg:h-40 sm:gap-1 lg:gap-2'>
        <div
          className='relative'
          onMouseOver={() => handleHoverAction('third-line')}
          onMouseLeave={() => handleLeaveAction()}
          onMouseDown={(e) => handleBetAction('third-line', e)}
        >
          <span className='text-white text-[10px] sm:text-sm lg:text-base bg-yellow1 hover:bg-yellow2 flex items-center justify-center border w-5 h-5 sm:w-8 sm:h-8 lg:w-12 lg:h-12'>
            2:1
          </span>
          {thirdLineItem &&
            <ChipItem
              {...thirdLineItem}
              classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute -top-0.5 -left-0.5 lg:top-1.5 lg:left-1.5'
              labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
            />
          }
        </div>
        <div
          className='relative'
          onMouseOver={() => handleHoverAction('second-line')}
          onMouseLeave={() => handleLeaveAction()}
          onMouseDown={(e) => handleBetAction('second-line', e)}
        >
          <span className='text-white text-[10px] sm:text-sm lg:text-base bg-yellow1 hover:bg-yellow2 flex items-center justify-center border w-5 h-5 sm:w-8 sm:h-8 lg:w-12 lg:h-12'>
            2:1
          </span>
          {secondLineItem &&
            <ChipItem
              {...secondLineItem}
              classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute -top-0.5 -left-0.5 lg:top-1.5 lg:left-1.5'
              labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
            />
          }
        </div>
        <div
          className='relative'
          onMouseOver={() => handleHoverAction('first-line')}
          onMouseLeave={() => handleLeaveAction()}
          onMouseDown={(e) => handleBetAction('first-line', e)}
        >
          <span className='text-white text-[10px] sm:text-sm lg:text-base bg-yellow1 hover:bg-yellow2 flex items-center justify-center border w-5 h-5 sm:w-8 sm:h-8 lg:w-12 lg:h-12'>
            2:1
          </span>
          {firstLineItem &&
            <ChipItem
              {...firstLineItem}
              classes='!w-[16px] !h-[16px] sm:!w-[28px] sm:!h-[28px] !absolute -top-0.5 -left-0.5 lg:top-1.5 lg:left-1.5'
              labelClasses='!w-[10px] !h-[10px] sm:!w-[18px] sm:!h-[18px] !text-[6px] sm:!text-[10px]'
            />
          }
        </div>
      </div>
    </div>
  )
}

export default NumsArea;
