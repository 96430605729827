import React, { FC, useEffect, useState } from "react";
import ReactSlider from 'react-slider'

import CommonLayout from "../../layouts/CommonLayout";
import BetDataTable from "../../components/BetDataTable";
import Text from "../../components/Text";
import bitcoinIcon from '../../assets/icons/bitcoin.svg';
import TextInput from "../../components/TextInput";
import CustomButton from "../../components/CustomButton";
import useAppData from "../../hooks/useAppData";
import diceService from "../../services/diceService";
import useAuth from "../../hooks/useAuth";

import diceIcon from '../../assets/images/dice.png'
import { PREV_GAME } from "../../helper/constants";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

const CalcItem = ({label, onAction}: {label: string, onAction:() =>void}) => (
  <span
    className="flex items-center justify-center w-full h-8 md:h-10 rounded cursor-pointer bg-gray2 text-white text-xs"
    onClick={onAction}
  >
    {label}
  </span>
)

const DicePage: FC = () => {
  const { isAuthenticated, user } = useAuth();
  const { curBalance, newSeed, updateBetList, updateUserBetList, updateBigRollers, updateWinners, updateGameResult, updateCurBalance } = useAppData();
  const [minAmount] = useState(0.00001);
  const [info, setInfo] = useState({
    betAmount: minAmount,
    profit: 0.0000878,
    roll: 50,
    overRoll: 50,
    payout: 0
  })
  const [payout, setPayout] = useState({
    under: (99 / 50).toFixed(4),
    over: (99 / (100 - 50)).toFixed(4),
  });
  const [calcList] = useState<number[]>([0.0001, 0.001, 0.01, 0.1]);

  const [won, setWon] = useState<number>(0);
  const [betRoll, setBetRoll] = useState<number>(-1)
  const [warnModal, setWarnModal] = useState<boolean>(false);
  const [warnTxt, setWarnTxt] = useState<string>('');
  
  useEffect(() => {
    if (isAuthenticated) {
      diceService.getList()
        .then(res => {
          if (res.$success) {
            updateBetList(res.data.betList, true);
            updateUserBetList(res.data.userBetList);
            updateBigRollers(res.data.bigRollers);
            updateWinners(res.data.winnerList);
          }
        })
    }
  }, [isAuthenticated])

  const handleChangeInfo = (field: string, value: number) => {
    const reg = /^[\d.]+$/;
    if (!value && !reg.test(value.toString())) return;

    if (value < 0) return;

    if (field === 'roll' || field === 'overRoll') {
      if (value < 1 || value > 99) return;
    }

    if (field === 'roll') {
      setPayout({
        under: (99 / value).toFixed(4),
        over: (99 / (100 - value)).toFixed(4),
      })
      setInfo(v => ({ ...v, roll: value, overRoll: 100 - value }));
    } else if (field === 'overRoll') {
      setPayout({
        under: (99 / (99 - value)).toFixed(4),
        over: (99 / (100 - (99 - value))).toFixed(4),
      })
      setInfo(v => ({ ...v, overRoll: value, roll: 100 - value }));
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      setInfo(v => ({ ...v, [field]: value }));
    }
  }

  const handleCalc = (value: string | number) => {
    switch (value) {
      case '1/2':
        setInfo(v => ({ ...v, betAmount: info.betAmount / 2 >= minAmount ? v.betAmount / 2 : minAmount }))
        break;
      case '2x':
        setInfo(v => ({ ...v, betAmount: info.betAmount * 2 <= curBalance ? v.betAmount * 2 : curBalance }))
        break;
      case 'MIN':
        setInfo(v => ({ ...v, betAmount: minAmount }))
        break;
      case 'MAX':
        setInfo(v => ({ ...v, betAmount: curBalance }))
        break;
      default:
        setInfo(v => ({ ...v, betAmount: parseFloat(value.toString()) }))
        break;
    }
  }

  const handleRollout = async (over: boolean) => { 
    if (curBalance >= info.betAmount && info.betAmount > 0) {
      setTimeout(async () => {
        const result = await diceService.rollout({
          betAmount: info.betAmount,
          roll: info.roll,
          over,
          playerSeed: `${newSeed.playerSeed}`,
        })
        if (result.$success && result.dice) {
          setWon(result.dice.wonAmount);
          setBetRoll(result.dice.bet);
          updateCurBalance(curBalance - info.betAmount + result.dice.wonAmount);
          updateBetList({ ...result.dice, username: user.username })
          updateUserBetList({ ...result.dice, username: user.username })
          updateBigRollers({ ...result.dice, username: user.username })
          updateGameResult({
            serverSeed: '(To see the server seed, you must request a new server seed)',
            playerSeed: result.dice.playerSeed,
            result: result.dice.result,
            newServerSeedHash: result.dice.newServerSeedHash,
          })
          localStorage.setItem(
            PREV_GAME,
            JSON.stringify({
              playerSeed: result.dice.playerSeed,
              result: result.dice.result,
              serverSeed: '(To see the server seed, you must request a new server seed)'
            }),
          );
        }
      }, 300)
    } else {
      console.log('info.betAmount: ', info.betAmount)
      setWarnTxt(
        parseFloat(info.betAmount.toString()) === 0 ?
        "Bet amount should be bigger than 0"
        :
        "Your balance is not enough. Please deposit."
      )
      setWarnModal(true)
    }
  }

  return (
    <CommonLayout classes="!px-4 xl:px-16">
      <div className="flex flex-col mb-4 md:mb-8 gap-5 py-10 w-full max-w-[660px] mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 px-0 md:px-10">
          <TextInput
            label="Balance"
            labelClasses="!text-xs !text-dark2 font-bold"
            classes="!gap-1"
            inputContainerClasses="bg-gray1 rounded py-2.5"
            inputClasses="text-right w-28 text-xs font-bold"
            value={curBalance ? curBalance.toFixed(7) : '0.0000000'}
            sub={<img src={bitcoinIcon} className="mr-2" />}
            onChange={e => handleChangeInfo('betAmount', e.target.value)}
          />
          <TextInput
            label="Won"
            labelClasses="!text-xs !text-dark2 font-bold"
            classes="!gap-1"
            inputContainerClasses="bg-gray1 rounded py-2.5"
            inputClasses="text-right w-28 text-xs font-bold"
            value={won.toFixed(6)}
            sub={<img src={bitcoinIcon} className="mr-2" />}
            onChange={e => handleChangeInfo('betAmount', e.target.value)}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 items-end px-0 md:px-10">
          <TextInput
            label="BET AMOUNT"
            labelClasses="!text-xs !text-dark2 font-bold"
            classes="!gap-1"
            inputContainerClasses="bg-gray1 rounded py-2.5"
            inputClasses="text-right w-28 text-xs font-bold"
            value={info.betAmount.toString()}
            sub={<img src={bitcoinIcon} className="mr-2" />}
            onChange={e => handleChangeInfo('betAmount', e.target.value)}
          />
          <div className="grid grid-cols-2 gap-2">
            <CalcItem label='1/2' onAction={() => handleCalc('1/2')} />
            <CalcItem label='2x' onAction={() => handleCalc('2x')} />
          </div>
          <div className="flex flex-row justify-between gap-2">
            {calcList.map((item, index) => (
              <CalcItem key={index} label={item.toString()} onAction={() => handleCalc(item)} />
            ))}
          </div>
          <div className="grid grid-cols-2 gap-2">
            <CalcItem label='MIN' onAction={() => handleCalc('MIN')} />
            <CalcItem label='MAX' onAction={() => handleCalc('MAX')} />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 px-0 md:px-10">
          <TextInput
            label="Win Chance Under"
            labelClasses="!text-xs !text-dark2 font-bold"
            classes="!gap-1 w-full"
            inputContainerClasses="bg-gray1 rounded py-2.5"
            inputClasses="text-right w-28 text-xs font-bold"
            sub={<Text type="xs-white" label="%" classes="mr-2 w-4" />}
            value={info.roll.toString()}
            onChange={e => handleChangeInfo('roll', e.target.value)}
          />
          <TextInput
            label="Win Chance Over"
            labelClasses="!text-xs !text-dark2 font-bold"
            classes="!gap-1 w-full"
            inputContainerClasses="bg-gray1 rounded py-2.5"
            inputClasses="text-right w-28 text-xs font-bold"
            sub={<Text type="xs-white" label="%" classes="mr-2 w-4" />}
            value={info.overRoll.toString()}
            onChange={e => handleChangeInfo('overRoll', e.target.value)}
          />
        </div>
        <div className="flex flex-row gap-x-4 px-0 md:px-10">
          <CustomButton
            classes="w-full xl:w-[450px] m-auto py-2.5 text-center font-medium"
            bg={info.roll >= 50 ? 'green' : 'red'}
            onAction={() => handleRollout(false)}
          >
            <Text type="sm-white" label={`Roll Under ${info.roll} ${payout.under ? ` x ${payout.under}` : ''}`} />
          </CustomButton>
          <CustomButton
            classes="w-full xl:w-[450px] m-auto py-2.5 text-center font-medium"
            bg={info.overRoll >= 50 ? 'green' : 'red'}
            onAction={() => handleRollout(true)}
          >
            <Text type="sm-white" label={`Roll Over ${info.roll} ${payout.over ? ` x ${payout.over}` : ''}`} />
          </CustomButton>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col xl:flex-row justify-between xl:items-end items-center gap-4 xl:gap-0 px-5 md:px-0">
              <div className="flex flex-col w-full relative pt-14">
                {betRoll > -1 &&
                  <div className="absolute w-full top-0">
                    <div className="relative">
                      <img className="absolute -translate-x-1/2 transition ease-in-out delay-150" style={{left: `${betRoll}%`}} src={diceIcon} width={60} />
                      <span className="absolute -translate-x-1/2 transition ease-in-out delay-150 mt-5 font-bold" style={{left: `${betRoll}%`}}>{betRoll.toFixed(2)}</span>
                    </div>
                  </div>
                }
                <ReactSlider
                  className="horizontal-slider w-full h-8 text-black"
                  thumbClassName="bg-gray-200 w-8 h-8 top-0 flex align-center justify-center pt-0.5 text-center font-medium rounded cursor-pointer"
                  trackClassName="bg-gray-400 h-1 rounded-full top-3"
                  value={info.roll}
                  onChange={e => handleChangeInfo('roll', e)}
                  renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                />
                <div className="flex flex-row justify-between w-full mb-2">
                  <Text type="sm-dark" classes="!text-14" label="0" />
                  <Text type="sm-dark" classes="!text-14" label="100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BetDataTable type="dice" />
      <Modal
        classes='pb-4'
        openModal={warnModal}
        title='Warning'
        closeModal={() => setWarnModal(false)}
      >
        <Text
          classes='!text-dark1'
          type='lg-dark'
          label={warnTxt}
        />
        <div className='flex flex-row justify-end gap-4'>
          <Button
            label='Close'
            classes='text-center w-20 !py-1'
            onAction={() => setWarnModal(false)}
          />
        </div>
      </Modal>
    </CommonLayout>
  )
}

export default DicePage
