import React, { FC } from 'react';

interface TextareaProps {
  classes?: string;
  cols?: number;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

const Textarea: FC<TextareaProps> = props => {
  const {
    classes = '',
    cols = 3,
    placeholder = '',
    value,
    onChange
  } = props;

  return (
    <textarea
      className={`bg-transparent border ${classes}`}
      cols={cols}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
    >
      {value}
    </textarea>
  )
}

export default Textarea;
