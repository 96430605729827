import React, { FC, useState, useEffect } from 'react';

import ChipItem from './ChipItem';
import { ChipList, NumbersArea } from '../../../helper/constants';
import chipIcon from '../../../assets/icons/chip_back.svg';
import Button from '../../Button';
import Text from '../../Text';
import RouletteBall from './RouletteBall';

interface ChipsProps {
  classes?: string;
  roll: number;
  unit: number;
  active: boolean;
  betAmount: number;
  wonAmount: number;
  onActive: (active: boolean) => void;
  onBetUnit: (value: number) => void;
  onAction: (kind: string) => void;
}

// const indexList = [5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10];
const indexList = [16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24];

const ChipsArea: FC<ChipsProps> = props => {
  const {
    classes = '',
    roll,
    unit,
    active,
    betAmount,
    wonAmount,
    onActive,
    onBetUnit,
    onAction,
  } = props;

  const [spin, setSpin] = useState<boolean>(false);
  const [posInfo, setPosInfo] = useState({ top: 0, left: 0 })
  const [oldRoll, setOldRoll] = useState<number>(roll);
  const [step, setStep] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false);

  const [wheelDeg, setWheelDeg] = useState<number>(0);
  const [ballDeg, setBallDeg] = useState<number>(42);
  const [history, setHistory] = useState<number[]>([]);

  useEffect(() => {
    if (spin) {
      onActive(spin);
    } else {
      setTimeout(() => {
        onActive(spin);
      }, 300)
    }
  }, [spin])

  useEffect(() => {
    if (roll && loading) {
      console.log('roll && loading: ', roll, oldRoll, loading);
      setLoading(false);

      const indexOld = indexList.indexOf(oldRoll);
      const indexNew = indexList.indexOf(roll);

      const dir_deg = parseFloat((360 * indexNew / 37).toFixed(5));
      const offset_wheel_deg = (wheelDeg + dir_deg) % 360;
      const mov_deg = 70 < 360 - offset_wheel_deg ? 360 - (290 - offset_wheel_deg) : offset_wheel_deg + 290;
      setWheelDeg(v => v - 360 - mov_deg)

      const offset = indexNew > indexOld ? indexNew - indexOld : indexNew - indexOld + 37
      const offset_deg = parseFloat((360 * offset / 37).toFixed(5));
      console.log('offset data: ', indexNew, indexOld, mov_deg, offset_deg);
      console.log('ball deg: ', ballDeg, offset_deg)

      if (ballDeg > -360) setBallDeg(v => v + 3600 + offset_deg)
      else setBallDeg(v => v + 3240 + offset_deg)

      setSpin(true);
      setTimeout(() => {
        setSpin(false);
        setStep(0);
        setHistory(v => [roll, ...v]);
      }, 10000);
      setOldRoll(roll);
    }
  }, [roll])

  useEffect(() => {
    if (spin && step < 401) {
      setTimeout(() => {
        setStep(v => v + 1);
        // if (step > 398)
        // console.log('offset deg data: ', offset * step );
        if (step < 300)
          setPosInfo({ top: step * 0.03, left: step * 0.03 })
      }, 20)
    }
  }, [step, spin])

  const handleBetUnit = (unit: number | string) => {
    if (unit === 'max')
      onBetUnit(9999)
    else if (typeof unit === 'number')
      onBetUnit(unit)
  }

  const wheelTransform = (deg: number) => {
    // return { transform: `translate(${posInfo.offsetX}px, ${posInfo.offsetY}px)`}
    return { transform: `rotate(${deg}deg)` }
  }

  const ballTransform = (deg: number) => {
    return {
      transform: `rotate(${deg}deg)`,
    }

  }

  // eslint-disable-next-line
  const positionInfo = () => {
    // return { transform: `translate(${info.offsetX}px, ${info.offsetY}px)` }
    return posInfo
  }

  const handleAction = (kind: string) => {
    if (kind === 'submit')
      handleSpinAction()
    else
      onAction(kind);
  }

  const handleSpinAction = () => {
    if (spin) return;

    setLoading(true);
    setPosInfo({ top: step * 0.1, left: step * 0.1 })
    onAction('submit');
  }

  return (
    <div className={`grid grid-cols-3 bg-gray1 px-4 py-6 gap-6 relative ${classes}`}>
      <div className='flex flex-col col-span-2 gap-4 z-20'>
        <div className='flex flex-row'>
          {history.length > 0 && history.map((item, index) => (
            <span
              key={index}
              className={`
                ${item === 0 ?
                  'bg-green-800'
                  :
                  `${NumbersArea.red.includes(item) ? 'bg-red-800' : 'bg-gray-800'}`
                }
                w-[6.66%] flex items-center justify-center text-white font-bold h-8 text-[10px] sm:text-sm
              `}
            >
              {item}
            </span>
          ))}
          {history.length < 15 && Array(15 - history.length).fill(0).map((item, index) => (
            <span
              key={index}
              className={`
                w-[6.66%] flex items-center justify-center text-white font-bold h-8 text-[10px] sm:text-sm
                ${index % 2 ? 'bg-gray-800' : 'bg-red-800'}
              `}
            >
              #
            </span>
          ))}
        </div>
        <div className='grid grid-cols-8 gap-1'>
          {ChipList.map((item, index) => (
            <ChipItem
              key={index}
              {...item}
              cat={true}
              active={item.value === unit}
              onSelect={handleBetUnit}
            />
          ))}
          <div
            className={`
                  bg-red-500 rounded-full relative flex items-center justify-center border-2 sm:border-4
                  ${unit === 9999 ?
                    'border-red-800 w-[30px] h-[30px] sm:w-[46px] sm:h-[46px] lg:w-[58px] lg:h-[58px] m-0 sm:m-0.5'
                    :
                    'border-gray1 w-[26px] h-[26px] sm:w-[38px] sm:h-[38px] lg:w-[52px] lg:h-[52px] m-0.5 sm:m-1.5'
                  }
                `}
            onClick={() => handleBetUnit('max')}
          >
            <img src={chipIcon} alt='chip' className='absolute top-0 bottom-0 left-0 right-0' />
            <span
              className='w-4 h-4 sm:w-6 sm:h-6 lg:w-8 lg:h-8 bg-white text-dark text-[8px] sm:text-[10px] lg:text-sm font-medium rounded-full flex items-center justify-center'
            >
              MAX
            </span>
          </div>
        </div>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 sm:px-8 lg:px-0'>
          <Button classes='font-bold py-1 text-sm sm:text-base lg:text-xl' label='Clear' bg="Blue" onAction={() => handleAction('clear')} />
          <Button classes='font-bold py-1 text-sm sm:text-base lg:text-xl' label='Double' bg="Blue" onAction={() => handleAction('double')} />
          <Button classes='font-bold py-1 text-sm sm:text-base lg:text-xl' label='Repeat' bg="Blue" onAction={() => handleAction('repeat')} />
          <Button classes='font-bold py-1 text-sm sm:text-base lg:text-xl' label='Spin' bg="Green" onAction={() => handleAction('submit')} />
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:px-8 lg:px-0'>
          <div className='flex flex-col lg:flex-row justify-center sm:items-center gap-2 sm:gap-4'>
            <Text label='Bet Amount :' classes='text-xs sm:!text-base' />
            <Text label={(betAmount / 100000000).toFixed(8)} type='white' />
          </div>
          <div className='flex flex-col lg:flex-row justify-center sm:items-center gap-2 sm:gap-4'>
            <Text label='Won Amount :' classes='text-xs sm:!text-base' />
            <Text label={(wonAmount).toFixed(8)} type='white' />
          </div>
        </div>
      </div>
      <div className={`absolute w-80 h-80 right-4 bottom-4 overflow-hidden z-10 wheel-section ${active && '!z-30'}`}>
        <div className={`wheel -right-28 -bottom-28 w-80 h-80 absolute ${spin && '!right-0 !bottom-0 active'}`}>
          <div
            className={`wheel-content right-28 bottom-28 w-80 h-80 ease-out ${spin && 'active'} p-[72px]`}
            style={wheelTransform(wheelDeg)}
          >
            <div
              className={`ball-container w-44 h-44 relative ease-out`}
              style={ballTransform(ballDeg)}
            >
              <RouletteBall classes='roulette-ball absolute' style={positionInfo()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChipsArea;
