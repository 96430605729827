import React, { FC, useState, useEffect } from 'react';
import CommonLayout from '../../layouts/CommonLayout';
import BetDataTable from '../../components/BetDataTable';

import NumsArea from '../../components/Games/Roulette/Nums';
import ChipsArea from '../../components/Games/Roulette/Chips';
import { RouletteBetItem } from '../../helper/types';
import rouletteService from '../../services/rouletteService';
import useAppData from '../../hooks/useAppData';
import useAuth from '../../hooks/useAuth';
import { PREV_GAME } from '../../helper/constants';
import Modal from '../../components/Modal';
import Text from '../../components/Text';
import Button from '../../components/Button';

const RoulettePage: FC = () => {
  const { isAuthenticated, user } = useAuth();
  const {
    curBalance,
    newSeed,
    updateBetList,
    updateUserBetList,
    updateBigRollers,
    updateWinners,
    updateGameResult,
    updateCurBalance
  } = useAppData();

  const [betResult, setBetResult] = useState({roll: 16, betAmount: 0, wonAmount: 0})
  const [showWon, setShowWon] = useState<number>(0);
  const [showRoll, setShowRoll] = useState<number>(-1)

  // eslint-disable-next-line
  const [wonRate, setWonRate] = useState<number>(0);
  const [betAmount, setBetAmount] = useState<number>(0);
  const [unitValue, setUnitValue] = useState<number>(100);
  const [betList, setBetList] = useState<RouletteBetItem[]>([]);
  const [oldBetList, setOldBetList] = useState<RouletteBetItem[]>([]);

  const [active, setActive] = useState<boolean>(false);
  const [activeLoading, setActiveLoading] = useState<boolean>(false);

  const [warnModal, setWarnModal] = useState<boolean>(false);
  const [warnTxt, setWarnTxt] = useState<string>('');

  // useEffect(() => {
  //   if (betList.length > 0) {
  //     let amount = 0;
  //     betList.forEach(item => {
  //       amount += item.betAmount;
  //     })
  //     setBetAmount(amount);
  //   }
  // }, [betList])

  useEffect(() => {
    if (active) {
      setActiveLoading(true)
    } else if (activeLoading) {
      updateCurBalance(curBalance + betResult.wonAmount);
      setActiveLoading(false)
      setShowRoll(betResult.roll);
      setShowWon(betResult.wonAmount);
      if (betResult.wonAmount) {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        setBetList(betResult.betResult.filter((item: any) => item.win).map((item: any) => ({...item, betAmount: item.wonAmount})))
        // setWarnTxt(
        //   `You won: ${wonRate} x :  ${betResult.wonAmount} BTC `
        // )
        // setWarnModal(true)
      } else {
        setBetList([]);
      }
    }
  }, [active])

  useEffect(() => {
    if (isAuthenticated) {
      rouletteService.getList()
        .then(res => {
          if (res.$success) {
            updateBetList(res.data.betList, true);
            updateUserBetList(res.data.userBetList);
            updateBigRollers(res.data.bigRollers);
            updateWinners(res.data.winnerList);
          }
        })
    }
  }, [isAuthenticated])

  const handleBetAction = (kind: string, increase: number) => {
    let betListStr: string[] = [];
    if (betList.length > 0) {
      betListStr = betList.map(item => item.area);
    }
    if (betListStr.includes(kind)) {
      let buf_betamount = 0;
      setBetList(
        betList
          .map(item => {
            let buf_item = {...item};
            if (item.area === kind)
              buf_item = {
                ...item,
                betAmount: item.betAmount + unitValue * increase < 0 ? 0 : item.betAmount + unitValue * increase
              };
            buf_betamount += item.betAmount;
            return buf_item;
          })
          .filter(item => item.betAmount)
      )
      setBetAmount(buf_betamount);
    } else if (increase === 1) {
      setBetList(v => [...v, { area: kind, betAmount: unitValue }])
      setBetAmount(v => v + unitValue);
    }
  }

  const handleAction = async (type: string) => {
    switch (type) {
      case 'clear':
        setBetList([]);
        setBetAmount(0)
        break;
      case 'double':
        if (betList.length > 0) {
          setBetList(betList.map((item) => ({ ...item, betAmount: item.betAmount * 2 })));
          setBetAmount(v => v * 2)
        }
        break;
      case 'repeat':
        setBetList(oldBetList);
        if (oldBetList.length > 0) {
          let bet_amount = 0;
          oldBetList.forEach(item => {
            bet_amount += item.betAmount
          })
          setBetAmount(bet_amount)
        } else {
          setBetAmount(0)
        }
        break;
      case 'submit': {
        if (curBalance >= betAmount / 100000000 && betList.filter(item => item.betAmount > 0).length > 0) {
          let betAmount = 0;
          betList.forEach(item => {
            betAmount += item.betAmount
          });

          setShowRoll(-1);
          setShowWon(0);
          const result = await rouletteService.rollout({
            betAmount,
            playerSeed: newSeed.playerSeed,
            details: betList.filter(item => item.betAmount > 0),
          });

          if (result.$success && result.roulette) {
            updateCurBalance(curBalance - result.roulette.betAmount);
            setBetResult(result.roulette)
            setWonRate(result.roulette.wonRate);
            updateBetList({ ...result.roulette, username: user.username })
            updateUserBetList({ ...result.roulette, username: user.username })
            updateBigRollers({ ...result.roulette, username: user.username })
            updateGameResult({
              serverSeed: '(To see the server seed, you must request a new server seed)',
              playerSeed: result.roulette.playerSeed,
              result: result.roulette.result,
              newServerSeedHash: result.roulette.newServerSeedHash,
            })
            localStorage.setItem(
              PREV_GAME,
              JSON.stringify({
                playerSeed: result.roulette.playerSeed,
                result: result.roulette.result,
                serverSeed: '(To see the server seed, you must request a new server seed)'
              }),
            );
            setOldBetList(betList);
          }
        } else {
          setWarnTxt(
            betList.filter(item => item.betAmount > 0).length === 0 ?
              "Bet amount should be bigger than 0"
              :
              "Your balance is not enough. Please deposit."
          )
          setWarnModal(true)
        }
        break;
      }
      default:
        break;
    }
  }

  return (
    <CommonLayout classes='!px-4 xl:px-16'>
      <div className="flex flex-col mb-4 md:mb-8 gap-5 py-10 w-full max-w-[320px] sm:max-w-[526px] lg:max-w-[800px] mx-auto">
        <NumsArea
          roll={showRoll}
          betList={betList}
          classes={`${active ? 'z-10' : 'z-20'}`}
          onBetAction={handleBetAction}
        />
        <ChipsArea
          classes={`${active ? 'z-20' : 'z-10'}`}
          roll={betResult.roll}
          unit={unitValue}
          active={active}
          betAmount={betAmount}
          wonAmount={showWon}
          onActive={setActive}
          onBetUnit={setUnitValue}
          onAction={handleAction}
        />
      </div>
      <BetDataTable type="roulette" />
      <Modal
        classes='pb-4'
        openModal={warnModal}
        title='Warning'
        closeModal={() => setWarnModal(false)}
      >
        <Text
          classes='!text-dark1'
          type='lg-dark'
          label={warnTxt}
        />
        <div className='flex flex-row justify-end gap-4'>
          <Button
            label='Close'
            classes='text-center w-20 !py-1'
            onAction={() => setWarnModal(false)}
          />
        </div>
      </Modal>
    </CommonLayout>
  )
}

export default RoulettePage;
