import React, { FC } from 'react';
import Text from './Text';

interface TextInputProps {
  classes?: string;
  labelClasses?: string;
  inputContainerClasses?: string;
  inputClasses?: string;
  label?: string;
  enableLabel?: boolean;
  placeholder?: string;
  value: string;
  type?: string;
  // eslint-disable-next-line
  sub?: any;
  // eslint-disable-next-line
  onChange?: (e: any) => void;
}

const TextInput: FC<TextInputProps> = props => {
  const {
    classes='',
    labelClasses='',
    inputClasses='',
    inputContainerClasses='',
    label = '',
    enableLabel = true,
    placeholder = '',
    value,
    type = 'text',
    sub = null,
    onChange = () => {}
  } = props;

  return (
    <div className={`flex flex-col gap-3 ${classes}`}>
      {enableLabel && <Text type='sm-white' classes={labelClasses} label={label} />}
      <div className={`flex flex-row justify-between border border-gray2 px-3 py-1.5 ${inputContainerClasses}`}>
        {sub}
        <input
          className={`outline-none bg-transparent text-white flex-1 ${inputClasses}`}
          type={type}
          min={0}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default TextInput;
