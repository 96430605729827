import React, { FC } from 'react';
import Text from './Text';
import { SelectItem } from '../helper/types';

interface SelectProps {
  label?: string;
  enableLabel?: boolean;
  placeholder?: string;
  value: string;
  optionList: SelectItem[];
  // eslint-disable-next-line
  onChange?: (e: any) => void;
}

const Select: FC<SelectProps> = props => {
  const {
    label = '',
    enableLabel = true,
    placeholder = '',
    optionList,
    value,
    onChange = () => {}
  } = props;

  return (
    <div className='flex flex-col gap-3'>
      {enableLabel && <Text type='sm-white' label={label} />}
      <select
        className='border border-gray2 px-3 py-1.5 outline-none bg-transparent text-white'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      >
        {optionList.length > 0 && optionList.map((item, index) => (
          <option key={index} className='bg-gray1' value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select;
