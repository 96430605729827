import React, { FC, useState, useEffect } from 'react';
import sha256 from 'crypto-js/sha256';

import Text from '../Text';
import TextInput from '../TextInput';
import { IGameResult, INewSeed } from '../../context/AppContext';
// import useAuth from '../../hooks/useAuth';
import useAppData from '../../hooks/useAppData';
import Modal from '../Modal';
import Button from '../Button';
import appService from '../../services/appService';
import { PREV_GAME, VerifyDesc } from '../../helper/constants';

interface ISeed extends IGameResult {
  // playerSeed: string;
}

interface BetProofProps {
  type: string;
  data: ISeed;
}

const BetProof: FC<BetProofProps> = (props) => {
  const { type } = props;
  // const { user } = useAuth();
  const { gameResult, newSeed, updateNewSeed, updateGameResult } = useAppData();

  const [seedData, setSeedData] = useState<IGameResult>({} as IGameResult);
  const [newSeedData, setNewSeedData] = useState<INewSeed>({} as INewSeed);

  const [newPlayerSeed, setNewPlayerSeed] = useState<string>('');
  const [visiblePlayerModal, setVisiblePlayerModal] = useState<boolean>(false);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState<boolean>(false);

  // useEffect(() => {
  //   if (user) {
  //     setSeedData(v => ({ ...v, playerSeed: user.playerSeed, serverSeed: user.serverSeed }))
  //   }
  // }, [user])

  useEffect(() => {
    if (gameResult.playerSeed) {
      setSeedData(gameResult);
    } else {
      const prevSeedData = localStorage.getItem(PREV_GAME);
      if (prevSeedData) {
        updateGameResult(JSON.parse(prevSeedData));
      }
    }
  }, [gameResult])

  useEffect(() => {
    setNewSeedData(newSeed);
  }, [newSeed])

  const handleRequestServerSeed = async () => {
    const res = await appService.fetchNewServerSeed();
    if (res.$success) {
      updateNewSeed({ ...newSeedData, serverSeed: res.seedInfo.serverHash });
      const resultData = { ...gameResult, serverSeed: res.seedInfo.oldServerSeed }
      updateGameResult(resultData);
      const prevSeedData = localStorage.getItem(PREV_GAME);
      if (prevSeedData) {
        localStorage.setItem(PREV_GAME, JSON.stringify({...JSON.parse(prevSeedData), serverSeed: res.seedInfo.oldServerSeed}));
      }
      handleCloseConfirmModal();
    }
  }

  const handleRandomzie = () => {
    const seed = sha256(Math.random() + '').toString();
    setNewPlayerSeed(seed);
  }

  const handleNewplayerSeed = (value: string) => {
    const reg = /^[1-9a-f]+$/;
    if (!value || reg.test(value)) {
      console.log('new values: ', value);
      setNewPlayerSeed(value);
    }
  }

  const handleClosePlayerModal = () => {
    setVisiblePlayerModal(false);
  }

  const handleCloseConfirmModal = () => {
    setVisibleConfirmModal(false);
  }

  const handleChangePlayerSeed = async () => {
    if (newPlayerSeed.length >= 8 && newPlayerSeed.length <= 64) {
      const res = await appService.putResetNonce();
      if (res.$success) {
        updateNewSeed({ ...newSeedData, playerSeed: newPlayerSeed });
        handleClosePlayerModal();
      }
    }
  }

  return (
    <div className='flex flex-col py-2 md:p-10 gap-3'>
      <Text type='xl-white' label='Next Game' classes='mb-1' />
      <div className='flex flex-col mb-3 gap-3'>
        <div className='flex flex-col'>
          <div className='flex flex-row gap-8 items-end'>
            <Text type='sm-white' label='Player Seed' />
            <div className='cursor-pointer' onClick={() => setVisiblePlayerModal(true)}>
              <Text type='xs-yellow' label='New Seed' />
            </div>
          </div>
          <TextInput
            value={newSeedData.playerSeed}
          />
        </div>
        <div className='flex flex-col'>
          <div className='flex flex-row gap-8 items-end'>
            <Text type='sm-white' label='Server Seed Hash' />
            <div className='cursor-pointer' onClick={() => setVisibleConfirmModal(true)}>
              <Text type='xs-yellow' label='Request New Seed' />
            </div>
          </div>
          <TextInput
            value={newSeedData.serverSeed}
          />
        </div>
      </div>
      <Text type='xl-white' label='Previous Game' classes='mb-1' />
      <div className='flex flex-col gap-3'>
        {/* <TextInput
          label='The result of the next game will be:'
          value='SHA256(Server Seed + SHA256(Player Seed))'
        /> */}
        <TextInput
          label='Server Seed:'
          value={seedData.serverSeed ? seedData.serverSeed : 'No game played'}
        />
        <TextInput
          label='Player Seed | Nonce'
          value={seedData.playerSeed ? seedData.playerSeed : 'No game played'}
        />
        <TextInput
          label="This Game's Result:"
          value={seedData.result ? seedData.result : 'No game played'}
        />
      </div>
      <div className='flex flex-col'>
        <Text type='white' label='To Verify:' />
        <div className='text-white ml-0 leading-8 ty'>
          {type === 'dice' ?
            <Text
              type='sm-white'
              label={`${VerifyDesc.dice}`}
            />
            :
            <>
              <Text
                type='sm-white'
                label={`${VerifyDesc.bitdropProof[0]}`}
              />
              <Text
                type='sm-yellow'
                label={` ${VerifyDesc.bitdropProof[1]}`}
              />
              <Text
                type='sm-white'
                label={` ${VerifyDesc.bitdropProof[2]}`}
              />
              <Text
                type='sm-yellow'
                label={`${VerifyDesc.bitdropProof[3]}`}
              />
            </>
          }
        </div>
      </div>
      <Modal
        classes='pb-8'
        openModal={visiblePlayerModal}
        title='Change Player Seed'
        closeModal={() => handleClosePlayerModal()}
      >
        <Text
          classes='text-center !text-dark1'
          label='Accepted characters: 0-9, a-f. Length must be between 8 and 64 characters.'
        />
        <div className='flex flex-col'>
          <div className='flex flex-row gap-1'>
            <Text classes='text-dark1 font-bold' type='dark' label='New Seed - ' />
            <div className='cursor-pointer' onClick={() => handleRandomzie()}>
              <Text classes='font-semibold' label='Randomize' />
            </div>
          </div>
          <TextInput
            inputClasses='!text-black'
            value={newPlayerSeed}
            onChange={e => handleNewplayerSeed(e.target.value)}
          />
        </div>
        <Button label='Change Seed' classes='!bg-dark1 text-center font-bold h-10' onAction={handleChangePlayerSeed} />
      </Modal>
      <Modal
        classes='pb-8'
        openModal={visibleConfirmModal}
        title='Confirm New Server Seed'
        closeModal={() => handleCloseConfirmModal()}
      >
        <Text
          classes='!text-dark1'
          type='lg-dark'
          label='Are you sure you want a new server seed?'
        />
        <div className='flex flex-row justify-end gap-4'>
          <Button
            label='Okay'
            classes='text-center w-20 !py-1'
            onAction={() => handleRequestServerSeed()}
          />
          <Button
            label='Cancel'
            classes='text-center !text-black w-20 !py-1 bg-transparent border border-yellow1 text-dark1'
            onAction={() => handleCloseConfirmModal()}
          />
        </div>
      </Modal>
    </div>
  )
}

export default BetProof;
