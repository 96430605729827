const getChipItem = (value: number) => {
  let label = '';
  let bg = '';

  if (value < 5) {
    bg = 'bg-gray-400';
  } else if (value < 10) {
    bg = 'bg-yellow-600';
  } else if (value < 50) {
    bg = 'bg-red-700';
  } else if (value < 100) {
    bg = 'bg-blue-700';
  } else if (value < 500) {
    bg = 'bg-green-800';
  } else if (value < 1000) {
    bg = 'bg-green-600';
  } else if (value < 5000) {
    bg = 'bg-orange-500';
  } else if (value < 10000) {
    bg = 'bg-gray-800';
  } else if (value < 50000) {
    bg = 'bg-pink-400';
  } else if (value < 100000) {
    bg = 'bg-purple-700';
  } else if (value < 500000) {
    bg = 'bg-gray-900';
  } else if (value < 1000000) {
    bg = 'bg-orange-800';
  } else if (value < 5000000) {
    bg = 'bg-cyan-700';
  } else if (value < 10000000) {
    bg = 'bg-green-900';
  }

  if (value < 1000) {
    label = value.toString();
  } else if (value < 1000000) {
    label = Math.round(value / 1000) + 'K'
  } else {
    label = (value / 100000000).toFixed(2) + 'B'
  }

  return { value, label, bg }
}

export {
  getChipItem,
}