import React, { FC } from 'react';
import Text from '../Text';

interface BetRulesProps {
  type: string;
}

const BetRules: FC<BetRulesProps> = () => {

  return (
    <div className='flex flex-col py-2 md:p-10'>
      <Text type='xl-white' label='Bit Drop Rules' classes='mb-4' />
      <Text type='white' label='How to Play:' classes='mb-2.5' />
      <ul className='list-disc text-white ml-5 leading-8'>
        <li>
          <Text type='sm-white' label='Choose a prize now' />
        </li>
        <ul className='list-disc text-white ml-7'>
          <li>
            <Text type='sm-white' label='Red: Highest variance, largest prizes' />
          </li>
          <li>
            <Text type='sm-white' label='Orange: High variance, large prizes' />
          </li>
          <li>
            <Text type='sm-white' label='Green: Moderate variance, mid-range prizes' />
          </li>
          <li>
            <Text type='sm-white' label='Blue: Low variance, most consistant prizes' />
          </li>
          <li>
            <Text type='sm-white' label='Purple: Lowest variance, most consistant prizes' />
          </li>
        </ul>
        <li>
          <Text type='sm-white' label="Input your bet per bit making sure it's below the limit at the end of your row." />
        </li>
        <li>
          <Text type='sm-white' label="Choose how many bits you'd like to drop (up to 100)" />
        </li>
        <li>
          <Text type='sm-white' label="Click play and watch the bits drop!" />
        </li>
        <li>
          <Text type='sm-white' label="You win the amount listed on the prize the bit hits" />
        </li>
      </ul>
    </div>
  )
}

export default BetRules;
