import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../views/auth/login';
import Register from '../views/auth/register';
import DashboardPage from '../views/dashboard';
import { APP_ROUTES } from '../helper/constants';
import EmptyPage from '../views/Empty';
import BitDropPage from '../views/bitdrop';
import Winners from '../views/winners';
import Contact from '../views/contact';
import Setting from '../views/setting';
import useAuth from '../hooks/useAuth';
import DicePage from '../views/dice';
import RoulettePage from '../views/roulette';

const Loading: FC = () => <p>Loading...</p>
export const AppRoutes: FC = () => {
    const { isAuthenticated } = useAuth();

    return (
        <React.Suspense fallback={<Loading />}>
            <Routes>
                <Route
                    path='/'
                    element={<DashboardPage />}
                />
                <Route path={APP_ROUTES.DICE} element={<DicePage />} />
                <Route path={APP_ROUTES.ROULETEE} element={<RoulettePage />} />
                <Route path={APP_ROUTES.BLACKJACK} element={<EmptyPage />} />
                <Route path={APP_ROUTES.POCKER} element={<EmptyPage />} />
                <Route path={APP_ROUTES.BITSANDBARS} element={<EmptyPage />} />
                <Route path={APP_ROUTES.BITDROP} element={<BitDropPage />} />
                <Route path={APP_ROUTES.MORE} element={<EmptyPage />} />
                <Route path={APP_ROUTES.CASINO} element={<EmptyPage />} />
                <Route path={APP_ROUTES.WINNERS} element={<Winners />} />
                <Route path={APP_ROUTES.CONTACT} element={<Contact />} />
                { isAuthenticated ? <>
                    <Route
                        path='/'
                        element={<DashboardPage />}
                    />
                    <Route path={APP_ROUTES.SETTING} element={<Setting />} />
                </> : <>
                    <Route path='/auth/signin' element={<Login />} />
                    <Route path='/auth/signup' element={<Register />} />
                </>}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </React.Suspense>
    )
}