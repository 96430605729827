import React, { FC } from 'react';

interface ImageProps {
  src: string;
  width?: number;
  height?: number;
  classes?: string;
}

const Image: FC<ImageProps> = props => {
  const {
    src,
    width = 30,
    height = 30,
    classes = ''
  } = props;

  return (
    <img
      src={src}
      width={width}
      height={height}
      className={classes}
    />
  )
}

export default Image;
